import React, { useEffect, useState } from 'react';
import { Typography, Card, CardHeader, styled, Box, Select, MenuItem, Paper } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, TooltipProps } from 'recharts';
import { ValueType, NameType } from 'recharts/types/component/DefaultTooltipContent';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { PageLoader } from '../../PageLoader';
import { dateRange } from '../constants';
import { useNumberDevicesFailedPolicies } from '../../utils/fetchRulesService';
import { useNumberApplicationsRegistered, useNumberDevicesRegistered } from '../../utils/fetchDeviceService';

export function RegisteredCard({
  instanceType,
  initialDateRange,
  setCustom,
}: {
  instanceType: 'Apps' | 'Devices';
  initialDateRange: number;
  setCustom: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [accessToken = ''] = useLocalStorage('accessToken', '');
  const [selectedDateRange, setSelectedDateRange] = useState(initialDateRange);

  const { numberDevicesRegistered = [], isLoading: isLoadingDevices } = useNumberDevicesRegistered(selectedDateRange, accessToken);
  const { numberDevicesFailedPolicies = [], isLoading: isLoadingPolicies } = useNumberDevicesFailedPolicies(selectedDateRange, accessToken);
  const { numberAppsRegistered = [], isLoading: isLoadingApps } = useNumberApplicationsRegistered(selectedDateRange, accessToken);

  const deviceData = numberDevicesRegistered.map((item, i) => ({
    ...item,
    ...numberDevicesFailedPolicies[i],
    numberOfDevicesAdjust: item.numberOfDevices - (numberDevicesFailedPolicies[i]?.numberOfFailedDevices || 0),
  }));

  useEffect(() => {
    setSelectedDateRange(initialDateRange);
  }, [instanceType, initialDateRange]);

  const handleDateRangeChange = event => {
    setSelectedDateRange(event.target.value);
    if (event.target.value !== initialDateRange) {
      setCustom(true);
    }
  };

  const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
    if (active) {
      return (
        <StyledPaper>
          <Typography variant="bodyRegular" color="white">
            {label}
          </Typography>
          <hr />
          <Typography variant="bodyRegular" color="white">
            {instanceType === 'Devices' ? 'Device policy failures' : 'Number of Applications'}
          </Typography>
          <br />
          <Typography variant="bodyRegular" color="white">
            {instanceType === 'Devices'
              ? `${payload?.[0].payload.numberOfFailedDevices} out of ${payload?.[0].payload.numberOfDevices}`
              : // : `${payload?.[0].payload.numberOfFailedApps} out of ${payload?.[0].payload.numberOfApps}`}
                `${payload?.[0].payload.numberOfApplications}`}
          </Typography>
        </StyledPaper>
      );
    }

    return null;
  };

  const Loader = () => {
    if (instanceType === 'Devices' && isLoadingDevices && isLoadingPolicies) {
      return <PageLoader />;
    }
    if (instanceType === 'Apps' && isLoadingApps) {
      return <PageLoader />;
    }

    return null;
  };

  return (
    <StyledCard>
      <Loader />
      <CardHeader
        title={
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h4Medium">{instanceType} Registered</Typography>
            <Select variant="standard" value={selectedDateRange} onChange={handleDateRangeChange} disableUnderline>
              {dateRange.map(range => (
                <MenuItem key={range.value} value={range.value}>
                  <em>{range.label}</em>
                </MenuItem>
              ))}
            </Select>
          </Box>
        }
      />
      <ResponsiveContainer width="100%" height="80%">
        <BarChart
          width={500}
          height={300}
          data={instanceType === 'Devices' ? deviceData : numberAppsRegistered}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <Legend
            layout="horizontal"
            verticalAlign="top"
            align="left"
            wrapperStyle={{
              paddingBottom: '20px',
            }}
            formatter={(value, entry, index) => <span style={{ color: 'black' }}>{value}</span>}
            payload={
              instanceType === 'Devices'
                ? [
                    { id: 'numberOfDevices', value: 'Number of devices', type: 'circle', color: 'rgba(16,89,191,0.38)' },
                    { id: 'numberOfFailedApps', value: 'Number of device policy failures', type: 'circle', color: '#487F87' },
                  ]
                : [
                    { id: 'numberAppsRegistered', value: 'Number of apps', type: 'circle', color: 'rgba(255,204,9,0.38)' },
                    // { id: 'numberOfFailedApps', value: 'Number of app policy failures', type: 'circle', color: '#FFCC09' },
                  ]
            }
          />
          <CartesianGrid vertical={false} />
          <XAxis dataKey="day" />
          <YAxis allowDecimals={false} />
          <Tooltip content={<CustomTooltip />} />

          {instanceType === 'Devices' ? (
            <>
              <Bar stackId="a" dataKey="numberOfFailedDevices" fill="#487F87" barSize={24} />
              <Bar stackId="a" dataKey="numberOfDevicesAdjust" fill="rgba(16,89,191,0.38)" barSize={24} radius={[7, 7, 0, 0]} />
            </>
          ) : (
            <>
              {/* <Bar stackId="b" dataKey="numberOfFailedApps" fill="#FFCC09" barSize={24} /> */}
              <Bar stackId="b" dataKey="numberOfApplications" fill="rgba(255,204,9,0.38)" barSize={24} radius={[7, 7, 0, 0]} />
            </>
          )}
        </BarChart>
      </ResponsiveContainer>
    </StyledCard>
  );
}

const StyledCard = styled(Card)({
  borderRadius: '8px',
  boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)',
  backgroundColor: '#FAFAFA',
  padding: '8px',
  height: '425px',
});

const StyledPaper = styled(Paper)({
  backgroundColor: 'black',
  width: '200px',
  height: '120px',
  padding: '10px 20px',
});
