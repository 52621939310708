import { Box, Card, CardContent, MenuItem, Paper, Select, styled, Typography } from '@mui/material';
import { ResponsiveContainer, Scatter, ScatterChart, Tooltip, TooltipProps, XAxis, YAxis, ZAxis } from 'recharts';
import { ValueType, NameType } from 'recharts/types/component/DefaultTooltipContent';
import { useState } from 'react';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { PageLoader } from '../../PageLoader';
import { useNumberApplicationsFailedPolicies, useNumberDevicesFailedPolicies } from '../../utils/fetchRulesService';
import { useNumberDevicesRegistered } from '../../utils/fetchDeviceService';

export const dateRange = [
  {
    label: 'Last week',
    value: 7,
  },
  {
    label: 'Last 2 weeks',
    value: 14,
  },
  {
    label: 'Last 3 weeks',
    value: 21,
  },
  {
    label: 'Last 4 weeks',
    value: 28,
  },
];

const CustomTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
  if (active) {
    const values = payload?.[0].payload;
    const name = payload?.[1].name as unknown as string;
    const singularName = name.slice(0, -1);
    let dataStr = '';
    let dataStr2 = '';
    if (values.value2 !== undefined && values.value2 !== null) {
      dataStr = `${singularName} policy failures`;
      dataStr2 = `${values.value2} out of ${values.value}`;
    } else {
      dataStr = `${values.value} ${values.value === 1 ? singularName : name}`;
      dataStr2 = '';
    }
    return (
      <StyledPaper>
        <Typography variant="bodyRegular" color="white">
          {values.day}
        </Typography>
        <hr />
        <Typography variant="bodyRegular" color="white">
          {dataStr}
          <br />
          {dataStr2}
        </Typography>
      </StyledPaper>
    );
  }

  return null;
};

interface RenderOneArgs {
  category?: string;
  data?: any[];
  dataKey?: string;
  data2?: any[];
  dataKey2?: string;
  fill?: string;
  height?: number;
}

export const PolicyStatisticsCard = ({ policyId }: { policyId: string }) => {
  const [selectedDateRange, setSelectedDateRange] = useState(7);
  const [accessToken = ''] = useLocalStorage('accessToken', '');
  const { numberDevicesFailedPolicies = [], isLoading: isLoadingFailedDevicePolicies } = useNumberDevicesFailedPolicies(
    selectedDateRange,
    accessToken,
    policyId,
  );
  const { numberDevicesRegistered = [], isLoading: isLoadingDevices } = useNumberDevicesRegistered(selectedDateRange, accessToken);
  const { numberOfFailedApplications, isLoading: isLoadingApplications } = useNumberApplicationsFailedPolicies(
    selectedDateRange,
    accessToken,
    policyId,
  );

  const handleDateRangeChange = event => {
    setSelectedDateRange(event.target.value);
  };

  const renderOne = ({ category, data, dataKey = null, data2, dataKey2 = null, fill, height = 160 }: RenderOneArgs) => {
    const rangeMax = 7000 / selectedDateRange;
    const range = [0, rangeMax];

    // merge the 2 data lists into one object
    const dayKeys = data && data.length ? data.map(d => d.day) : (data2 || []).map(d => d.day);
    const dataMap = Object.fromEntries(dayKeys.map(day => [day, { data: null, data2: null }]));
    for (const d of data || []) {
      dataMap[d.day].data = d[dataKey];
    }
    for (const d of data2 || []) {
      dataMap[d.day].data2 = d[dataKey2];
    }
    const combinedData = dayKeys.map(day => ({ day, value: dataMap[day].data, value2: dataMap[day].data2, index: 0.5 }));

    const d1Vals = (data || []).map(d => d[dataKey]);
    const d2Vals = (data2 || []).map(d => d[dataKey2]);
    const domain = [1, Math.max(...d1Vals, ...d2Vals)];

    return (
      <ResponsiveContainer width="100%" height={height}>
        <ScatterChart margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
          <XAxis
            type="category"
            dataKey="day"
            tick={{ fontSize: 0 }}
            tickLine={false}
            axisLine={{ transform: `translate(0, -65)` }}
            allowDuplicatedCategory={false}
          />

          <YAxis
            type="number"
            dataKey="index"
            name={category}
            tick={false}
            width={100}
            domain={[0, 1]}
            axisLine={false}
            label={{ value: category, position: 'insideRight' }}
          />

          <ZAxis zAxisId={0} type="number" dataKey="value" domain={domain} range={range} />
          <ZAxis zAxisId={1} type="number" dataKey="value2" domain={domain} range={range} />
          <Tooltip content={<CustomTooltip />} />
          <Scatter zAxisId={0} data={combinedData} fill={fill} opacity={0.4} dataKey="value" />
          {data2 && <Scatter zAxisId={1} data={combinedData} fill={fill} dataKey="value2" />}
        </ScatterChart>
      </ResponsiveContainer>
    );
  };

  return (
    <StyledCard>
      <CardContent>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5Medium" marginLeft={2}>
            Number of devices violating this policy
          </Typography>
          <Select variant="standard" value={selectedDateRange} onChange={handleDateRangeChange} disableUnderline>
            {dateRange.map(range => (
              <MenuItem key={range.value} value={range.value}>
                <em>{range.label}</em>
              </MenuItem>
            ))}
          </Select>
        </Box>
        {(isLoadingFailedDevicePolicies || isLoadingDevices || isLoadingApplications) && <PageLoader />}
        <div style={{ width: '100%' }}>
          {renderOne({
            category: 'Devices',
            data: numberDevicesFailedPolicies,
            dataKey: 'numberOfDevices',
            data2: numberDevicesFailedPolicies,
            dataKey2: 'numberOfFailedDevices',
            fill: '#4FC3F7',
          })}
          {renderOne({
            category: 'Applications',
            data: numberOfFailedApplications,
            dataKey: 'numberOfFailedApplications',
            fill: '#8884d8',
          })}
          {/* This is just responsible for drawing the axix below the graphs */}
          <ResponsiveContainer width="100%" height={50}>
            <ScatterChart margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
              <XAxis type="category" dataKey="day" interval={selectedDateRange <= 14 ? 0 : 7} tickLine={false} />
              <YAxis type="number" dataKey="index" name="" tick={false} tickLine={false} width={100} domain={[0, 1]} axisLine={false} />
              <ZAxis zAxisId={0} range={[0, 0]} />
              <Scatter data={numberDevicesRegistered} dataKey="none" />
            </ScatterChart>
          </ResponsiveContainer>
        </div>
      </CardContent>
    </StyledCard>
  );
};

const StyledCard = styled(Card)({
  height: '426px',
  borderRadius: '8px',
  boxShadow: '0 10px 14px 0 rgba(0,0,0,0.04)',
});

const StyledPaper = styled(Paper)({
  backgroundColor: 'black',
  width: '200px',
  height: '120px',
  padding: '10px 20px',
});
