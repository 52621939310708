import type { Device } from '@kw/device-service/dist/device/entities/device.entity';
import type { DeviceState } from '@kw/device-service/dist/device/entities/deviceState.entity';
import { Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useDevices } from '../../pages/devices';
import { KwScoreBadge } from '../../kw-ui-components/KwRiskBadge';
import { KwColumnSortHeader } from '../../kw-ui-components/KwColumnSortHeader';
import { useDeviceFilterParams } from '../Devices/useDeviceSortParams';
import { KwTable } from '../../kw-ui-components/KwTable';
import { KwTableBody } from '../../kw-ui-components/KwTableBody';
import { KwTableCell } from '../../kw-ui-components/KwTableCell';
import { KwTableHead } from '../../kw-ui-components/KwTableHead';
import { KwTableRow } from '../../kw-ui-components/KwTableRow';
import { KwPaginationRouter } from '../../kw-ui-components/Pagination/KwPaginationRouter';
import { KwRowsPerPage } from '../../kw-ui-components/Pagination/KwRowsPerPage';
import { useKwPagination } from '../../kw-ui-components/Pagination/useKwPagination';
import { PageLoader } from '../../PageLoader';
import { getMostRecentState } from '../../utils/getMostRecentState';
import { ViewVersionOption } from './@types/ViewVersionOption';
import { useViewVersionSelect, ViewVersionSelect } from './ViewVersionSelect';

interface IProps {
  appName: string;
  appId: string;
  version: number;
  accessToken: string;
  os: string;
}

export const DeviceForAppVersionTable = ({ appName, appId, version, accessToken, os }: IProps) => {
  const {
    deviceNameOrder,
    toggleDeviceNameOrder,
    securityRiskLevelOrder,
    toggleSecurityRiskLevelOrder,
    privacyRiskLevelOrder,
    togglePrivacyRiskLevelOrder,
    numberOfAppsOrder,
  } = useDeviceFilterParams();

  const { selectValue, setSelectValue } = useViewVersionSelect();

  const { page, pageSize, setPage, handleChangePage, handleChangeRowsPerPage } = useKwPagination(10);

  const { devices, devicesError, total } = useDevices(
    true,
    {
      page: String(page),
      pageSize: String(pageSize),
      minSecurityRiskLevel: 0,
      maxSecurityRiskLevel: 10,
      minPrivacyRiskLevel: 0,
      maxPrivacyRiskLevel: 10,
      installedApps: appId,
      installedAppVersions: selectValue === ViewVersionOption['current-version'] ? version : undefined,
      os,
      deviceNameOrder,
      securityRiskLevelOrder,
      privacyRiskLevelOrder,
      numberOfAppsOrder,
    },
    accessToken,
  );

  if (devicesError) {
    console.error(devicesError);

    return <div>failed to load</div>;
  }

  if (!devices) {
    return <PageLoader />;
  }

  const tableHeaders = [
    {
      title: 'Device Name',
      onClick: toggleDeviceNameOrder,
      sortValue: deviceNameOrder,
    },
    { title: 'Platform Version' },
    {
      title: 'Device Security Risk Level',
      onClick: toggleSecurityRiskLevelOrder,
      sortValue: securityRiskLevelOrder,
    },
    {
      title: 'Device Privacy Risk Level',
      onClick: togglePrivacyRiskLevelOrder,
      sortValue: privacyRiskLevelOrder,
    },
    { title: 'App Version' },
    { title: 'App Security Risk Level' },
    { title: 'App Privacy Risk Level' },
  ];

  const totalPageCount = Math.ceil(total / pageSize);

  return (
    <StyledTableWrapper>
      <StyledTableTitle>
        <Typography variant="h4Medium">Devices with {appName}</Typography>
      </StyledTableTitle>
      <StyledTableTitleDivider orientation="horizontal" />

      <StyledTopTablePaginationWrapper>
        <ViewVersionSelect selectValue={selectValue} setSelectValue={setSelectValue} />
        {total > 10 ? (
          <>
            <KwRowsPerPage
              rowsPerPageOptions={[10, 20, 50, 100]}
              pageSize={pageSize}
              page={page}
              totalNumberOfEntries={total}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <StyledPagination page={page} totalPageCount={totalPageCount} handleChangePage={handleChangePage} />
          </>
        ) : null}
      </StyledTopTablePaginationWrapper>

      <KwTable aria-label={`Devices with ${appName}`}>
        <KwTableHead>
          <KwTableRow>
            {tableHeaders.map(({ title, sortValue, onClick }) => (
              <KwColumnSortHeader
                key={title}
                title={title}
                onClick={() => {
                  onClick();
                  setPage(0);
                }}
                sortValue={sortValue}
              />
            ))}
          </KwTableRow>
        </KwTableHead>
        <KwTableBody>
          {devices.map((device: Device) => {
            const deviceState = getMostRecentState<DeviceState>(device.states);

            const appVersion = deviceState.applicationStates.find(
              appState => appState.applicationVersion.applicationId === appId,
            ).applicationVersion;
            return (
              <KwTableRow key={device.id}>
                <KwTableCell selected={deviceNameOrder}>{device.name}</KwTableCell>
                <KwTableCell>{deviceState.osVersion}</KwTableCell>
                <KwTableCell selected={securityRiskLevelOrder}>
                  {typeof deviceState.securityRiskLevel === 'number' && <KwScoreBadge value={Math.floor(deviceState.securityRiskLevel)} />}
                </KwTableCell>
                <KwTableCell selected={privacyRiskLevelOrder}>
                  {typeof deviceState.privacyRiskLevel === 'number' && <KwScoreBadge value={Math.floor(deviceState.privacyRiskLevel)} />}
                </KwTableCell>
                <KwTableCell>{appVersion.version}</KwTableCell>
                <KwTableCell>
                  {typeof appVersion.securityRiskLevel === 'number' && <KwScoreBadge value={Math.floor(appVersion.securityRiskLevel)} />}
                </KwTableCell>
                <KwTableCell>
                  {typeof appVersion.privacyRiskLevel === 'number' && <KwScoreBadge value={Math.floor(appVersion.privacyRiskLevel)} />}
                </KwTableCell>
              </KwTableRow>
            );
          })}
        </KwTableBody>
      </KwTable>
      {total > 10 ? (
        <StyledBottomPaginationWrapper>
          <KwRowsPerPage
            rowsPerPageOptions={[10, 20, 50, 100]}
            pageSize={pageSize}
            page={page}
            totalNumberOfEntries={total}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <StyledPagination page={page} totalPageCount={totalPageCount} handleChangePage={handleChangePage} />
        </StyledBottomPaginationWrapper>
      ) : null}
    </StyledTableWrapper>
  );
};

/** styles */

const StyledTableWrapper = styled('div')`
  border-top: 1px solid ${props => props.theme.palette.greyOverride[300]};
`;

/* prettier-ignore */
const StyledTablePaginationWrapper = styled('div')`
display: grid;

  background-color: #fafafa;
  padding-right: 16px;
  gap: 16px;
  height: 64px;
`;

const StyledBottomPaginationWrapper = styled(StyledTablePaginationWrapper)`
  display: flex;
  justify-content: space-between;
`;

const StyledTopTablePaginationWrapper = styled(StyledTablePaginationWrapper)`
  grid-template:
    'view-version         rows-per-page           ...               pagination' 64px
    / 235px auto auto 88px;
`;

const StyledPagination = styled(KwPaginationRouter)`
  display: grid;
  grid-area: pagination;
  place-items: center;
`;

const StyledTableTitleDivider = styled(Divider)`
  background-color: ${props => props.theme.palette.accent.primary};
`;

const StyledTableTitle = styled('div')`
  height: 66px;
  width: 100%;
  padding: 16px;
  background-color: ${props => props.theme.palette.greyOverride[50]};
`;
