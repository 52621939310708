import { Provider as RollbarProvider } from '@rollbar/react';
import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { useAuth0 } from '@auth0/auth0-react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { HashRouter as Router, Routes, Route, Outlet, Navigate } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ReactNode, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';

import { useLDClient } from 'launchdarkly-react-client-sdk';
import { theme } from '../theme';
import { createEmotionCache } from '../createEmotionCache';
import { useLocalStorage } from '../utils/useLocalStorage';
import { AuthPermissions } from '../auth/AuthPermissions';
import { verifyAuthPermission } from '../auth/VerifyAuthPermission';

import Index from './index';
import Applications from './applications/index';
import ApplicationDetails from './applications/application-details';
import Devices from './devices/index';
import DeviceDetails from './devices/device-details';
import DeviceOwners from './device-owners';
import Layout from '../components/Layout';
import Policies from './policies/index';
import Rules from './rules/index';
import Settings from './settings/index';
import ManagedApps from './managed-apps';
import { Dashboard } from './dashboard';
import { fetchService } from '../utils/fetchService';
import { jwtPayload } from '../auth';
import { appMetadata } from '../components/constants';
import { verifyAuthExpiration } from '../auth/VerifyAuthExpiration';
import { Register } from './register';
import OktaTroubleshooting from './okta-troubleshooting';

// extend ProviderProps for @rollbar/react Provider (RollbarProvider) to avoid overload error (bug)
declare module '@rollbar/react' {
  interface ProviderProps {
    children: ReactNode;
  }
}

export default function App() {
  ReactGA.initialize([
    {
      trackingId: process.env.GA4_ID,
    },
  ]);

  const rollbarConfig = {
    enabled: process.env.NODE_ENV === 'production',
    accessToken: '24a2bce8fad747d094182c5682b141dd',
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: process.env.NODE_ENV,
    },
    autoInstrument: {
      network: true,
      networkResponseHeaders: true,
      networkResponseBody: true,
      networkRequestBody: true,
    },
  };

  const cache = createEmotionCache();

  const ProtectedRoute = ({ authPermission }: { authPermission: string }) => {
    const [accessToken = ''] = useLocalStorage('accessToken', '');

    if (verifyAuthPermission(accessToken, authPermission)) {
      return <Outlet />;
    }
    return <Navigate to={'/dashboard'} />;
  };

  const RequiresLogin = () => {
    // check and initialize auth and LaunchDarkly
    const checkAuthAndinitialize = async () => {
      // only proceed with auth checks if the auth0 client is initialized (isLoading === false)
      if (!auth0.isLoading) {
        if (!accessToken) {
          // user has no existing session go ahead and create a new one
          try {
            // get access token from auth0 and put into localstorage
            const obj = await auth0.getAccessTokenSilently();
            updateAccessToken(obj);
          } catch (err) {
            console.warn(err);
          }
        } else {
          // existing session detected, confirm if not expired
          verifyAuthExpiration(jwtPayload(accessToken), auth0);
        }

        if (!auth0.user) {
          // when an auth0.logout() occurs it removes the user from session, thus
          // we need to bring them back to login page.
          auth0.loginWithRedirect();
        }

        if (accessToken && auth0.isAuthenticated) {
          // now need to initialize LaunchDarkly context. Client should already be initialized
          // by provider in `~/src/main.tsx`
          const org = await fetchService(`/organizations/`, accessToken);
          const orgId = jwtPayload(accessToken)[appMetadata].organizationId;
          if (orgId === org.id) {
            ldClient.identify({
              kind: 'user',
              key: org.id, // LaunchDarkly is targeted to orgs
              name: org.name,
            });
          } else {
            console.warn('Unable to initialize LaunchDarkly, no org associated that matches token');
          }
          setInitialLoad(false);
        }
      }
    };
    checkAuthAndinitialize();
    if (initialLoad) return <div>Loading...</div>;
    return <Outlet />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const auth0 = useAuth0();
  const [accessToken = '', updateAccessToken] = useLocalStorage('accessToken', '');
  const ldClient = useLDClient();
  // this is used to determine whether the app is fully initialized
  // auth & LD are ready to go.
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {});

  return (
    <RollbarProvider config={rollbarConfig}>
      <CacheProvider value={cache}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
            <HelmetProvider>
              <Helmet>
                {/* PWA primary color */}
                <meta name="theme-color" content={theme.palette.accent.primary} />
              </Helmet>
              <CssBaseline />
              <Router>
                <Routes>
                  <Route element={<RequiresLogin />}>
                    <Route path="/" element={<Index />} />
                    <Route element={<Layout />}>
                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route path="/devices" element={<Devices />} />
                      <Route path="/device-details" element={<DeviceDetails />} />
                      <Route element={<ProtectedRoute authPermission={AuthPermissions.LIST_APP_VERSIONS} />}>
                        <Route path="/applications" element={<Applications />} />
                      </Route>
                      <Route element={<ProtectedRoute authPermission={AuthPermissions.READ_APP} />}>
                        <Route path="/applications/application-details" element={<ApplicationDetails />} />
                      </Route>
                      <Route path="/rules" element={<Rules />} />
                      <Route path="/device-owners" element={<DeviceOwners />} />
                      <Route path="/settings" element={<Settings />} />
                    </Route>
                    {/* Managed Apps / Policies child routes render Layout independently */}
                    <Route path="/managed-apps/*" element={<ManagedApps />} />
                    <Route path="/policies/*" element={<Policies />} />
                  </Route>
                  <Route path="/register" element={<Register />} />
                  <Route path="/okta-troubleshooting" element={<OktaTroubleshooting />} />
                </Routes>
              </Router>
            </HelmetProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </CacheProvider>
    </RollbarProvider>
  );
}
