import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useRollbar } from '@rollbar/react';
import { PageLoader } from '../PageLoader';
import { useLocalStorage } from '../utils/useLocalStorage';

export default function Index() {
  const auth0 = useAuth0();
  const [accessToken = ''] = useLocalStorage('accessToken', '');

  const navigate = useNavigate();

  const rollbar = useRollbar();

  if (auth0.error) {
    console.error(`Auth0 error: ${auth0.error.message}`);
  }

  useEffect(() => {
    if (accessToken && auth0.isAuthenticated && !auth0.isLoading) {
      if (process.env.NODE_ENV === 'production') {
        // update rollbar configuration
        rollbar.configure({
          payload: {
            person: {
              id: auth0.user.sub,
              email: auth0.user.email,
            },
          },
        });
      }
      navigate('/dashboard');
    }
  });

  // log matador app version once
  useEffect(() => {
    console.log('Matador Version: ', process.env.VERSION);
  }, []);

  if (auth0.isLoading) return <div>Loading...</div>;
  if (auth0.error) return <div>{auth0.error.message}</div>;

  return <PageLoader />;
}
