export interface PolicyAction {
  id: number;
  name: string;
  description: string;
  type: string;
  key: string;
}

export function getActions(): PolicyAction[] {
  return [
    {
      id: 1,
      name: 'Email users',
      description: 'email your users',
      type: 'messaging',
      key: 'MAIL',
    },
    {
      id: 2,
      name: 'Suspend user in Okta',
      description: '',
      type: 'suspension',
      key: 'CLOUD',
    },
    {
      id: 4,
      name: 'Send push notification',
      description: '',
      type: 'messaging',
      key: 'PUSH_NOTIFICATION',
    },
  ];
}
