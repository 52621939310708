import { Typography, Card, CardHeader, CardContent, styled } from '@mui/material';
import { TrendingUp, TrendingDown, TrendingFlat } from '@mui/icons-material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { NumDeviceFailedPolicies } from '@kw/rules-service/dist/src/policy/dtos/NumDeviceFailedPolicies.dto';
import { useFetchService } from '../../utils/fetchService';
import { PageLoader } from '../../PageLoader';
import { useLocalStorage } from '../../utils/useLocalStorage';

function useNumberDevicesFailedPolicies(range: number, accessToken) {
  const { data, error } = useFetchService<NumDeviceFailedPolicies[]>(
    `/rule/policies/number-devices-failed-policies?range=${range * 2}`,
    accessToken,
  );

  const isLoadingPolicies = !data && !error;

  function averageNumberOfDevicesFailed(devicesData: NumDeviceFailedPolicies[]) {
    return Math.round(
      devicesData.reduce((p, n) => {
        return p + n.numberOfFailedDevices;
      }, 0) / devicesData.length,
    );
  }

  let currentPeriodAvg = 0;
  let percentChange = 0;
  if (!isLoadingPolicies && data) {
    const previousPeriod = data.slice(0, data.length / 2);
    const currentPeriod = data.slice(data.length / 2);
    const previousPeriodAvg = averageNumberOfDevicesFailed(previousPeriod);
    currentPeriodAvg = averageNumberOfDevicesFailed(currentPeriod);

    if (previousPeriodAvg !== 0) {
      percentChange = Math.round(((currentPeriodAvg - previousPeriodAvg) / previousPeriodAvg) * 100.0);
    } else {
      // Technically this is undefined (infinite % increase), but just treating it as 100% for each
      percentChange = currentPeriodAvg * 100.0;
    }
  }

  return { numberDevicesFailedPolicies: currentPeriodAvg, percentChange, error, isLoadingPolicies };
}

export function FailedDeviceStatCard({ initialDateRange }: { initialDateRange: number }) {
  const [accessToken = ''] = useLocalStorage('accessToken', '');
  const {
    numberDevicesFailedPolicies = 0,
    percentChange = 0,
    isLoadingPolicies,
  } = useNumberDevicesFailedPolicies(initialDateRange, accessToken);

  const TrendingLine = ({ value }: { value: number }) => {
    if (value > 0) {
      return <StyledTrendingUpIcon color="warning" />;
    }

    if (value === 0) {
      return <StyledTrendingFlatIcon color="info" />;
    }

    return <StyledTrendingDownIcon color="success" />;
  };

  const Loader = () => {
    if (isLoadingPolicies) {
      return <PageLoader />;
    }

    return null;
  };

  return (
    <StyledCard>
      <Loader />
      <CardHeader title={<Typography variant="h4Medium">Average Device Violations per Day</Typography>}></CardHeader>
      <StyledCardContent>
        <div style={{ fontSize: '60px', marginRight: '20px' }}>
          {numberDevicesFailedPolicies}
          <div style={{ width: '100%' }} />
          <div style={{ fontSize: '18px' }}>Devices</div>
        </div>
        <TrendingLine value={Number(percentChange)} />
        <Typography variant="h5Medium">
          {percentChange}% from last {initialDateRange} days
        </Typography>
      </StyledCardContent>
    </StyledCard>
  );
}

const StyledCard = styled(Card)({
  borderRadius: '8px',
  boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)',
  backgroundColor: '#FAFAFA',
  padding: '8px',
});

const StyledCardContent = styled(CardContent)({
  lineHeight: '53px',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
});

const StyledTrendingUpIcon = styled(TrendingUp)({
  fontSize: '30px',
  marginRight: '10px',
});

const StyledTrendingDownIcon = styled(TrendingDown)({ fontSize: '30px', marginRight: '10px' });

const StyledTrendingFlatIcon = styled(TrendingFlat)({
  fontSize: '30px',
  marginRight: '10px',
});
