import { styled } from '@mui/material/styles';
import { Box, Link } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { QTabContext, QTabList, QTab, QTabPanel } from '@kw/quokka-ui';
import classnames from 'classnames';
import { KwContainer } from '../../kw-ui-components/KwContainer';
import { Integrations } from '../../components/Settings/Integrations';
import { OrganizationSettings } from '../../components/Settings/OrganizationSettings';

function a11yProps(index: number) {
  return {
    id: `settings-tab-${index}`,
    'aria-controls': `settings-tabpanel-${index}`,
  };
}

export default function Settings() {
  const [activeTab, setActiveTab] = useState<string>('0');

  const handleChange = (_event: ChangeEvent<HTMLButtonElement>, tab: string) => {
    setActiveTab(tab);
  };

  return (
    <KwContainer>
      <StyledTabContainer>
        <QTabContext value={activeTab}>
          <QTabList tabs onChange={handleChange}>
            <StyledQTab label="Integrations" value="0" {...a11yProps(0)}>
              <Link className={classnames({ active: activeTab === '0' })} onClick={() => setActiveTab('0')}>
                Integrations
              </Link>
            </StyledQTab>
            <StyledQTab label="Organization Settings" value="1" {...a11yProps(1)}>
              <Link className={classnames({ active: activeTab === '1' })} onClick={() => setActiveTab('1')}>
                Organization Settings
              </Link>
            </StyledQTab>
          </QTabList>
          <StyledQTabPanel tabId="0" value="0" index={0}>
            <Integrations />
          </StyledQTabPanel>
          <StyledQTabPanel tabId="1" value="1" index={1}>
            <OrganizationSettings />
          </StyledQTabPanel>
        </QTabContext>
      </StyledTabContainer>
    </KwContainer>
  );
}

const StyledQTab = styled(QTab)`
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
  color: #757575;
  &.Mui-selected {
    color: #000;
    font-weight: 700;
  }
  padding: 20px 40px;
`;

const StyledQTabPanel = styled(QTabPanel)`
  padding: 24px 0;
`;

const StyledTabContainer = styled(Box)`
  border-bottom: 1px solid ${props => props.theme.palette.greyOverride[300]};
`;
