import { Link } from '@mui/material';
import React, { useState } from 'react';
import { DashboardExpandableDataCard } from './DashboardExpandableDataCard';
import { useFetchService } from '../../utils/fetchService';
import { useLocalStorage } from '../../utils/useLocalStorage';

const useTopFailedPolicies = ({ range, accessToken }: { range: number; accessToken: string }) => {
  const path = `/rule/policies/top-failed-policies?range=${range}&limit=10`;
  const { data, error } = useFetchService(path, accessToken);
  const isLoading = !data && !error;
  return { topFailedPolicies: data, isLoading };
};

export function TopTenPoliciesCard({
  initialDateRange,
  setCustom,
}: {
  initialDateRange: number;
  setCustom: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [range, setRange] = useState(initialDateRange);
  const [accessToken = ''] = useLocalStorage('accessToken', '');
  const { topFailedPolicies, isLoading } = useTopFailedPolicies({ range, accessToken });

  return (
    <DashboardExpandableDataCard
      initialDateRange={initialDateRange}
      title="Top 10 Policies"
      data={topFailedPolicies}
      isLoading={isLoading}
      minRowShow={4}
      maxRowShow={10}
      rowIdField={'policyId'}
      columns={[
        {
          label: 'Name',
          keyName: 'policyName',
          align: 'left',
          component: ({ row, value }) => <Link href={`/#/policies/view/${row.policyId}`}>{value}</Link>,
        },
        { label: '# of devices', keyName: 'numDevicesFailed', align: 'right' },
      ]}
      functionToFetch={(params: { range: number }) => {
        setRange(params.range);
        if (params.range !== initialDateRange) {
          setCustom(true);
        }
      }}
    />
  );
}
