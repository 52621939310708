import { Link, List, ListItemButton, ListItemText, Popover } from '@mui/material';
import { css, styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Dispatch, SetStateAction } from 'react';
import { useSnackbar } from 'notistack';
import { sendHttpRequest } from '../../utils/network.service';

interface PopoverProps {
  policy: any;
  policyId: string;
  anchorEl: HTMLElement;
  setPolicyId: React.Dispatch<number>;
  setAnchorEl: React.Dispatch<HTMLElement>;
  handleClose: (setPolicyId: React.Dispatch<number>, setAnchorEl: React.Dispatch<HTMLElement>) => void;
  mutate?: () => void;
  selected?: string[];
  setSelected?: Dispatch<SetStateAction<string[]>>;
}

export function PoliciesTableAssociatedRulesPopover({ policy, policyId, anchorEl, setPolicyId, setAnchorEl, handleClose }: PopoverProps) {
  const rulesOpen = Boolean(anchorEl);
  const rulesId = rulesOpen ? 'simple-popover' : undefined;

  return (
    <StyledPopover
      id={rulesId}
      open={policyId === policy.id}
      anchorEl={anchorEl}
      onClose={() => handleClose(setPolicyId, setAnchorEl)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <div className="popover-text">
        {policy.policyRules.map(policyRule => policyRule.rule.name).join(', ')}
        <StyledLink onClick={() => handleClose(setPolicyId, setAnchorEl)}> Show less</StyledLink>
      </div>
    </StyledPopover>
  );
}

export function PoliciesTableMorePopover({
  policy,
  policyId,
  anchorEl,
  setPolicyId,
  setAnchorEl,
  handleClose,
  mutate,
  selected,
  setSelected,
}: PopoverProps) {
  const navigate = useNavigate();
  const options = ['Edit', 'Delete', 'Duplicate'];
  const moreOpen = Boolean(anchorEl);
  const moreId = moreOpen ? 'simple-popover' : undefined;

  const { enqueueSnackbar } = useSnackbar();

  const handlePopoverListItemClick = async (value: 'Edit' | 'Delete' | 'Duplicate') => {
    if (value === 'Edit') {
      navigate(`/policies/edit/${policyId}`);
    }

    if (value === 'Delete') {
      await sendHttpRequest({
        path: `${process.env.RULES_SERVICE_URL}/rule/policies/policy/${policyId}`,
        method: 'DELETE',
        successMessage: 'Successfully deleted policy',
        errorMessage: 'Error deleting policy',
        enqueueSnackbar,
      });

      removeFromSelected(selected, policyId);
      mutate();
    }

    if (value === 'Duplicate') {
      await sendHttpRequest({
        path: `${process.env.RULES_SERVICE_URL}/rule/policies/duplicate/${policyId}`,
        method: 'POST',
        successMessage: 'Successfully duplicated policy',
        errorMessage: 'Error duplicating policy',
        enqueueSnackbar,
      });

      removeFromSelected(selected, policyId);
      mutate();
    }

    handleClose(setPolicyId, setAnchorEl);
  };

  const removeFromSelected = (selectedPolicies: string[], id: string) => {
    setSelected([...selectedPolicies.filter((p: string) => p !== id)]);
  };

  return (
    <StyledPopover id={moreId} open={policyId === policy.id} anchorEl={anchorEl} onClose={() => handleClose(setPolicyId, setAnchorEl)}>
      <StyledList>
        {options.map((option: 'Edit' | 'Delete' | 'Duplicate') => (
          <ListItemButton autoFocus onClick={() => handlePopoverListItemClick(option)} key={option}>
            <ListItemText primary={option} />
          </ListItemButton>
        ))}
      </StyledList>
    </StyledPopover>
  );
}

const StyledLink = styled(Link)`
  cursor: pointer;
  margin-left: 2px;
`;

const StyledList = styled(List)`
  padding-top: 0;
  padding-bottom: 0;
`;

const rulesPopoverStyles = css`
  & div.popover-text {
    max-width: 300px;
    font-size: 0.875rem;
    padding: 12px;
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const StyledPopover = styled(Popover)`
  ${rulesPopoverStyles}
`;
