import { useEffect} from "react";
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga4'

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    // track pageview with gtag / react-ga / react-ga4, for example:
    console.log('Sending GA event for path: ', location.pathname + location.search)
    ReactGA.send({
        hitType: "pageview",
        page: location.pathname + location.search
      })
  }, [location]);
};

export default usePageTracking;
