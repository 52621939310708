// eslint-disable-next-line import/no-extraneous-dependencies
import { NumDeviceFailedPolicies } from '@kw/rules-service/dist/src/policy/dtos/NumDeviceFailedPolicies.dto';
import { NumApplicationFailedPolicies } from '@kw/rules-service/dist/src/policy/dtos/NumApplicationFailedPolicies.dto';
import { useFetchService } from './fetchService';
import { dateFormatMonthDay } from './dates';

/*
These are some rules-service fetch commands that are used in more than once place, so I pulled them into here so as not to repeat ourselves
 */

export function useNumberDevicesFailedPolicies(range: number, accessToken = '', policyId = '') {
  const { data, error } = useFetchService<NumDeviceFailedPolicies[]>(
    `/rule/policies/number-devices-failed-policies?range=${range}&policyId=${policyId}`,
    accessToken,
  );
  return {
    numberDevicesFailedPolicies: dateFormatMonthDay(data) as NumDeviceFailedPolicies[],
    error,
    isLoading: !data && !error,
  };
}

export function useNumberApplicationsFailedPolicies(range: number, accessToken, policyId) {
  const { data, error } = useFetchService<NumApplicationFailedPolicies[]>(`/rule/policies/number-applications-failed-policies?range=${range}&policyId=${policyId}`, accessToken);
  return { numberOfFailedApplications: dateFormatMonthDay(data), error, isLoading: !data && !error };
}
