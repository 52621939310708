import type { Application } from '@kw/device-service/dist/application/entities/application.entity';
import type { Indicator as RiskIndicator } from '@kw/service-definitions-risk_score';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { KwContainer } from '../../kw-ui-components/KwContainer';
import { AppVersionsTable } from '../../components/Applications/AppVersionsTable';
import { DeviceForAppVersionTable } from '../../components/Applications/DeviceForAppVersionTable';
import { IorRiskPane } from '../../components/Applications/IorRiskPane';
import { Link } from '../../Link';
import { PageLoader } from '../../PageLoader';
import { useFetchService } from '../../utils/fetchService';
import { useStickyResult } from '../../utils/useStickyResult';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { formatPlatform } from '../../utils/formatPlatform';

const useApp = (appId, os, accessToken) => {
  const { data, error: appDataError } = useFetchService<Application>(`/application/${appId}/${os}`, accessToken);
  const appData = { app: data };
  const { app } = useStickyResult(appData);

  return { app, appDataError };
};

export default function ApplicationDetails() {
  const [accessToken = ''] = useLocalStorage('accessToken', '');
  const location = useLocation();

  const { appId, appName, appVersion, os } = Object.fromEntries(new URLSearchParams(location.search));

  const { app, appDataError } = useApp(appId, os, accessToken);

  if (appDataError) {
    console.error(appDataError);
    return <div>failed to load</div>;
  }

  if (!app) {
    return <PageLoader />;
  }

  const currentAppVersion = app.versions.find(version => version.version === appVersion);

  const appMetaData = [
    ['Developer', app.developer],
    ['App ID', app.id],
    ['Version', appVersion],
    ['Platform', formatPlatform(app.os)],
  ];

  const { riskIndicators } = currentAppVersion;
  const securityIors = (riskIndicators as unknown as RiskIndicator[]).filter(ind => ind.isSecurityConcern);

  const privacyIors = (riskIndicators as unknown as RiskIndicator[]).filter(ind => ind.isPrivacyConcern);

  return (
    <StyledKwContainer>
      <StyledBackButton href="/applications">
        <StyledArrowBack />
        <StyledBackText>Back to App List</StyledBackText>
      </StyledBackButton>
      <StyledContent>
        <StyledMetaDataPane aria-label={`${appName}-metadata`}>
          <tbody>
            <StyledMetaDataRow>
              {appMetaData.map(([title, dataValue]) =>
                dataValue ? (
                  <td key={title + dataValue}>
                    <StyledMetaDataTitle>{title}</StyledMetaDataTitle>
                    <StyledMetaDataText>{dataValue}</StyledMetaDataText>
                  </td>
                ) : null,
              )}
            </StyledMetaDataRow>
          </tbody>
        </StyledMetaDataPane>

        <IorRiskPane
          title="Security Risk Level"
          score={Math.floor(currentAppVersion.securityRiskLevel)}
          numberOfIors={securityIors.length}
          topIors={securityIors.map(({ description }) => description)}
        />

        <IorRiskPane
          title="Privacy Risk Level"
          score={currentAppVersion.privacyRiskLevel}
          numberOfIors={privacyIors.length}
          topIors={privacyIors.map(({ description }) => description)}
        />

        <AppVersionsTable accessToken={accessToken} appName={appName} appId={appId} os={os} />
        <DeviceForAppVersionTable appName={appName} appId={appId} version={currentAppVersion.version} accessToken={accessToken} os={os} />
      </StyledContent>
    </StyledKwContainer>
  );
}

/** styles */

const StyledBackButton = styled(Link)`
  display: flex;
  height: 30px;
  padding-left: 18px;
  padding-right: 22px;
  column-gap: 11px;
  width: 177px;
  border-radius: 4px;
  margin-bottom: 7px;

  align-items: center;
  text-decoration: none;
  color: ${props => props.theme.palette.greyOverride[800]};

  &:hover {
    background-color: ${props => props.theme.palette.greyOverride[200]};
  }
`;

const StyledBackText = styled('div')`
  font-size: 13px;
  line-height: 13px;
`;

const StyledArrowBack = styled(ArrowBackIcon)`
  width: 16px;
`;

const StyledKwContainer = styled(KwContainer)`
  padding: 8px 32px;
`;

const StyledContent = styled('div')`
  display: grid;
  grid-row-gap: 24px;
  margin-bottom: 35px;
`;

const StyledMetaDataPane = styled('table')`
  padding: 24px;
  width: 100%;
  background-color: ${props => props.theme.palette.greyOverride[50]};
`;

const StyledMetaDataRow = styled('tr')`
  display: flex;
  justify-content: start;
  column-gap: 24px;
`;

const StyledMetaDataTitle = styled('div')`
  font-size: 10px;
  line-height: 14px;
  color: #818181;
`;

const StyledMetaDataText = styled('div')`
  font-size: 14px;
  line-height: 21px;
  color: #424242;
`;
