import { Box, Link, List, ListItemButton, ListItemText, Popover, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Dispatch, useContext } from 'react';
import type { KeyedMutator } from 'swr';
import { ManagedAppsInfoContext } from './ManagedAppInfoContext';
// eslint-disable-next-line import/extensions
import { ManagedAppsProfileMode } from './enum/ManagedAppsProfileMode.enum';

interface PopoverProps {
  app?: any;
  appId?: string;
  anchorEl: HTMLElement;
  setAppId?: Dispatch<string>;
  setAnchorEl: Dispatch<HTMLElement>;
  handleClose: (setAnchorEl?: Dispatch<HTMLElement>, setAppId?: Dispatch<string>) => void;
  setShowModal?: Dispatch<boolean>;
  setModalAppInfo?: Dispatch<any>;
  setModalCategory?: Dispatch<string>;
  mutate?: KeyedMutator<any>;
  description?: string;
}

export function ManagedAppsTableMorePopover({
  app,
  appId,
  anchorEl,
  setAppId,
  setAnchorEl,
  handleClose,
  setShowModal,
  setModalAppInfo,
  setModalCategory,
}: PopoverProps) {
  const navigate = useNavigate();
  const { setApp, setMode, setPlatform } = useContext(ManagedAppsInfoContext);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const options = [app.published ? 'Unpublish App' : 'Publish App', 'Edit', 'Remove'];

  const handlePopoverListItemClick = async (value: 'Publish App' | 'Unpublish App' | 'Edit' | 'Remove') => {
    if (value === 'Edit') {
      setApp(app);
      setPlatform(app.platform);
      setMode(ManagedAppsProfileMode.EDIT);
      navigate(`/managed-apps/edit/${appId}`);
    }
    if (value === 'Publish App' || value === 'Unpublish App') {
      const modalAction = value === 'Publish App' ? 'publish' : 'unpublish';
      setModalAppInfo(app);
      setModalCategory(modalAction);
      setShowModal(true);
    }

    if (value === 'Remove') {
      setModalAppInfo(app);
      setModalCategory('remove');
      setShowModal(true);
    }
    handleClose(setAnchorEl, setAppId);
  };

  return (
    <Popover
      id={id}
      open={appId === app.managedAppId}
      anchorEl={anchorEl}
      onClose={() => handleClose(setAnchorEl, setAppId)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <StyledList>
        {options.map((option: 'Publish App' | 'Unpublish App' | 'Edit' | 'Remove') => (
          <ListItemButton autoFocus onClick={() => handlePopoverListItemClick(option)} key={option}>
            <ListItemText primary={option} />
          </ListItemButton>
        ))}
      </StyledList>
    </Popover>
  );
}

export function ManagedAppsTableAddAppPopover({ anchorEl, setAnchorEl, handleClose }: PopoverProps) {
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const options = ['iOS', 'Android'];
  const { setPlatform } = useContext(ManagedAppsInfoContext);
  const handlePopoverListItemClick = (value: 'iOS' | 'Android') => {
    setPlatform(value.toLowerCase());
    navigate('/managed-apps/search');
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={() => handleClose(setAnchorEl)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <StyledList>
        {options.map((option: 'iOS' | 'Android') => (
          <ListItemButton autoFocus onClick={() => handlePopoverListItemClick(option)} key={option}>
            <ListItemText primary={option} />
          </ListItemButton>
        ))}
      </StyledList>
    </Popover>
  );
}

export function ManagedAppsProfileDescriptionPopover({ anchorEl, setAnchorEl, handleClose, description }: PopoverProps) {
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <StyledPopover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={() => handleClose(setAnchorEl)}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
    >
      <StyledBox>
        <StyledCloseIcon onClick={() => handleClose()} />
        <StyledTypography>
          {description}
          <StyledLink onClick={() => handleClose()}> Show less</StyledLink>
        </StyledTypography>
      </StyledBox>
    </StyledPopover>
  );
}

const StyledList = styled(List)`
  min-width: 110px;
  padding-top: 0;
  padding-bottom: 0;
`;

const StyledPopover = styled(Popover)`
  width: 75%;
  padding: 10px;
`;

const StyledTypography = styled(Typography)`
  white-space: pre-line;
  margin: 10px;
`;

const StyledLink = styled(Link)`
  cursor: pointer;
  margin-left: 2px;
`;

const StyledBox = styled(Box)`
  padding: 20px 16px;
`;

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;
