import { Add, Check, FilterAltOutlined, Search } from '@mui/icons-material';
import {
  CardContent,
  IconButton,
  OutlinedInput,
  List,
  ListItem,
  ListItemText,
  styled,
  Box,
  Card,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';

import { useEffect, useState, type Dispatch, type MouseEventHandler, type SetStateAction, type ChangeEvent } from 'react';
import { RuleEntity } from '@kw/service-definitions-rule';
import { KwButton } from '../../kw-ui-components/KwButton/KwButton';
// eslint-disable-next-line import/extensions
import { PolicyProfileMode } from './enum/PolicyProfileMode.enum';
// eslint-disable-next-line import/extensions
import { PolicyTab } from './enum/PolicyTab.enum';
import type { PolicyAction } from '../../pages/policies/getData';

type PolicyCardData<T> = Array<T>;
interface CardContentProps {
  tab: string;
  data: PolicyCardData<PolicyAction | RuleEntity>;
  selectedData: PolicyCardData<PolicyAction | RuleEntity>;
  addAction: Dispatch<SetStateAction<PolicyAction | RuleEntity>>;
  index: string;
  mode: string;
}

interface ListButtonProps {
  isSelected: boolean;
  action: MouseEventHandler<HTMLButtonElement>;
}

interface ListRowProps {
  row: PolicyAction | RuleEntity;
}

interface ListCardProps {
  tab: string;
  setTab: Dispatch<SetStateAction<PolicyTab>>;
  rules: RuleEntity[];
  actions: PolicyAction[];
  selectedRules: RuleEntity[];
  selectedActions: PolicyAction[];
  handleAddingRules: Dispatch<SetStateAction<RuleEntity>>;
  handleAddingActions: Dispatch<SetStateAction<PolicyAction>>;
  mode: string;
}

const PolicyListCardContent = ({ tab, data = [], selectedData = [], addAction, index, mode }: CardContentProps) => {
  const [selectedItems, setSelectedItems] = useState(selectedData.map(item => item.id));
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    // FIXME: This feels very hacky and doesn't seem like it's the right way to handle this
    // What was happening was there was infinite rerenders since React saw the selectedItems hook
    // changing each time, but the thing I'm not sure sure about is this hook should only
    // be called when "selectedData" changes, which it doesn't.
    if (selectedData.length !== selectedItems.length) {
      setSelectedItems(selectedData.map(item => item.id));
    }
  }, [selectedData, selectedItems.length]);

  const handleButtonClick = (row: PolicyAction | RuleEntity) => {
    if (selectedItems.includes(row.id)) {
      setSelectedItems(selectedItems.filter(item => item !== row.id));
    } else {
      setSelectedItems(prevSelectedItems => [...prevSelectedItems, row.id]);
    }

    addAction(row);
  };

  function getItemVal(item: PolicyAction | RuleEntity, property: string): string {
    return item[property];
  }

  const ListButton = ({ isSelected, action }: ListButtonProps) => {
    return isSelected ? (
      <KwButton startIcon={<Check />} disabled>
        Added
      </KwButton>
    ) : (
      <KwButton variant="basic" startIcon={<Add />} onClick={action} disabled={mode === PolicyProfileMode.VIEW} data-testid="add-button">
        Add
      </KwButton>
    );
  };

  const ListRow = ({ row }: ListRowProps) => {
    const isSelected = selectedItems.includes(row.id);
    return (
      <>
        <ListItem
          key={row.id}
          secondaryAction={<ListButton isSelected={isSelected} action={() => handleButtonClick(row)} />}
          divider={true}
        >
          <ListItemText primary={row.name} secondary={row.description} />
        </ListItem>
      </>
    );
  };

  const filterData =
    searchText.length > 0
      ? data.filter(
          (item: RuleEntity) =>
            item.name.toLowerCase().includes(searchText.toLowerCase()) || item.description.toLowerCase().includes(searchText.toLowerCase()),
        )
      : data;

  return (
    tab === index && (
      <CardContent>
        {index === PolicyTab.RULES && (
          <StyledToolHeader>
            <IconButton>
              <FilterAltOutlined />
            </IconButton>

            <StyledSearchBar placeholder="Search" startAdornment={<Search />} onChange={e => setSearchText(e.target.value)} />
          </StyledToolHeader>
        )}
        <StyledList>
          {index === PolicyTab.ACTIONS ? (
            <div>
              <StyledSection>
                <Typography variant="h5Medium">Messaging</Typography>
                {data
                  .filter((row: PolicyAction) => getItemVal(row, 'type') === 'messaging')
                  .map((row: PolicyAction) => (
                    <ListRow row={row} key={getItemVal(row, 'id')} />
                  ))}
              </StyledSection>
              <StyledSection>
                <Typography variant="h5Medium">Suspension</Typography>
                {data
                  .filter((row: PolicyAction) => getItemVal(row, 'type') === 'suspension')
                  .map((row: PolicyAction) => (
                    <ListRow row={row} key={getItemVal(row, 'id')} />
                  ))}
              </StyledSection>
            </div>
          ) : (
            <div>
              <StyledSection>
                {filterData.map((row: RuleEntity) => (
                  <ListRow row={row} key={getItemVal(row, 'id')} />
                ))}
              </StyledSection>
            </div>
          )}
        </StyledList>
      </CardContent>
    )
  );
};

export const PolicyListCard = ({
  tab,
  setTab,
  rules,
  actions,
  selectedRules,
  selectedActions,
  handleAddingRules,
  handleAddingActions,
  mode,
}: ListCardProps) => {
  const handleTabChange = (_event: ChangeEvent<HTMLButtonElement>, newValue: PolicyTab) => {
    setTab(newValue);
  };

  return (
    <StyledCard>
      <TabHeader>
        <Tabs value={tab} onChange={handleTabChange} variant="fullWidth">
          <StyledTab label={<Typography variant="h5Medium">Rules</Typography>} value={PolicyTab.RULES} />
          <StyledTab label={<Typography variant="h5Medium">Actions</Typography>} value={PolicyTab.ACTIONS} />
        </Tabs>
      </TabHeader>

      <PolicyListCardContent
        tab={tab}
        data={rules}
        selectedData={selectedRules}
        addAction={handleAddingRules}
        index={PolicyTab.RULES}
        mode={mode}
      />
      <PolicyListCardContent
        tab={tab}
        data={actions}
        selectedData={selectedActions}
        addAction={handleAddingActions}
        index={PolicyTab.ACTIONS}
        mode={mode}
      />
    </StyledCard>
  );
};

const StyledList = styled(List)({
  maxHeight: '600px',
  overflow: 'auto',
});

const StyledSection = styled('div')({
  marginTop: '24px',
  marginBottom: '24px',
});

const StyledSearchBar = styled(OutlinedInput)({
  width: '90%',
  height: '40px',
  backgroundColor: 'white',
});

const StyledCard = styled(Card)({
  width: '100%',
  height: 771,
  backgroundColor: 'rgba(79, 134, 142, 0.1)',
  padding: '2% 1%',
  borderRadius: '8px',
});

const TabHeader = styled(Box)({ borderBottom: '1px solid #D8D8D8' });

const StyledTab = styled(Tab)({
  textTransform: 'none',
});

const StyledToolHeader = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});
