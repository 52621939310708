import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { useSelect } from 'use-mui';
import { SelectArrowWrapper, SelectIcon } from '../../kw-ui-components/KwSelect';
import { ViewVersionOption } from './@types/ViewVersionOption';

type UseViewVersionSelectProps = {
  selectValue: ViewVersionOption;
  setSelectValue: React.Dispatch<React.SetStateAction<ViewVersionOption>>;
};

export const useViewVersionSelect = (): UseViewVersionSelectProps => {
  const [selectValue, setSelectValue] = useState<ViewVersionOption>(ViewVersionOption['current-version']);

  return { selectValue, setSelectValue };
};

export const ViewVersionSelect = ({ selectValue, setSelectValue, ...props }: UseViewVersionSelectProps) => {
  const { open, handleOpen, handleClose } = useSelect({
    defaultValue: [],
  });
  return (
    <StyledContainer {...props}>
      <Typography variant="bodySemiBold">View</Typography>
      <StyledFormControl variant="standard">
        <StyledSelect
          value={selectValue}
          onOpen={handleOpen}
          onClose={handleClose}
          onChange={e => setSelectValue(e.target.value as ViewVersionOption)}
          IconComponent={() => (open ? <StyledArrowUp /> : <StyledArrowDown />)}
        >
          <StyledMenuItem value={ViewVersionOption['current-version']}>This version only</StyledMenuItem>
          <StyledMenuItem value={ViewVersionOption['all-versions']}>All versions</StyledMenuItem>
        </StyledSelect>
      </StyledFormControl>
    </StyledContainer>
  );
};

const StyledArrowDown = () => {
  return (
    <StyledArrowWrapper>
      <SelectIcon width={10} height={6} alt="select-down-arrow" src={'/icons/chevron-down.svg'} />
    </StyledArrowWrapper>
  );
};

const StyledArrowUp = () => {
  return (
    <StyledArrowWrapper>
      <SelectIcon width={10} height={6} alt="select-down-arrow" src={'/icons/chevron-up.svg'} />
    </StyledArrowWrapper>
  );
};

const StyledContainer = styled('div')`
  display: flex;
  padding-left: 16px;
  align-items: center;
  grid-area: view-version;
`;

const StyledFormControl = styled(FormControl)``;

const StyledArrowWrapper = styled(SelectArrowWrapper)`
  transform: translateY(-7px);
  margin-right: 16px;
`;

const StyledSelect = styled(Select)`
  width: 148px;
  font-size: 13px;
  line-height: 30px;
  height: 30px;
  padding-left: 11px;

  .MuiSelect-select {
    padding: 5px 0 4px;
    padding-right: 8px !important;
    &:focus {
      background-color: transparent;
    }
  }
  &:after {
    border: none;
  }
  &:before {
    border: none;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 13px;
  min-height: 35px;
`;
