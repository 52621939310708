import { Typography, styled, Box, Select, MenuItem } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { KwContainer } from '../../kw-ui-components/KwContainer';
import { FailedDeviceStatCard } from '../../components/Dashboard/FailedDeviceStatCard';
import { CountriesContactCard } from '../../components/Dashboard/CountriesContactCard';
import { TopTenPoliciesCard } from '../../components/Dashboard/TopTenPoliciesCard';
import { RegisteredCard } from '../../components/Dashboard/RegisteredCard';
import { TopTenRiskScoreCard } from '../../components/Dashboard/TopTenRiskScoreCard';
import { TopIorsCard } from '../../components/Dashboard/TopIorsCard';
import { dateRange } from '../../components/constants';

import { KwButton } from '../../kw-ui-components/KwButton/KwButton';
import { LaunchDarklyFlags } from '../../launch-darkly';

function DashboardComponent({ flags }: LaunchDarklyFlags) {
  const [selectedDateRange, setSelectedDateRange] = useState(7);
  const [custom, setCustom] = useState(false);

  const { isAuthenticated, isLoading, user } = useAuth0();

  const username = isAuthenticated && !isLoading && user.given_name;

  const handleDateRangeChange = event => {
    setCustom(false);
    setSelectedDateRange(event.target.value);
  };

  const Greetings = ({ name }: { name: string }) => {
    const time = dayjs().hour();
    let greetings;

    if (time >= 0 && time < 12) {
      greetings = 'Good morning';
    } else if (time >= 12 && time < 18) {
      greetings = 'Good afternoon';
    } else {
      greetings = 'Good evening';
    }

    return (
      <Typography variant="h1Bold">
        {greetings}, {name}
      </Typography>
    );
  };
  return (
    <KwContainer>
      <StyledPanel>
        <StyledHeaderSection>
          <Box display="flex" justifyContent="space-between">
            <Greetings name={username} />
            {flags.uiTest && <h3>Test Flag</h3>}
            <KwButton startIcon={<FileDownloadOutlinedIcon />} variant="filled">
              Export
            </KwButton>
          </Box>
        </StyledHeaderSection>
        <StyledSubHeaderSection>
          <Select variant="standard" value={custom ? 'custom' : selectedDateRange} onChange={handleDateRangeChange} disableUnderline>
            {custom && <MenuItem value={'custom'}>Custom</MenuItem>}
            {dateRange.map(range => (
              <MenuItem key={range.value} value={range.value}>
                {range.label}
              </MenuItem>
            ))}
          </Select>
        </StyledSubHeaderSection>
        <StyledFailedDeviceStatSection>
          <FailedDeviceStatCard initialDateRange={selectedDateRange} />
        </StyledFailedDeviceStatSection>
        <StyledCountriesContactedSection>
          <CountriesContactCard initialDateRange={selectedDateRange} setCustom={setCustom} />
        </StyledCountriesContactedSection>
        <StyledTopPolicySection>
          <TopTenPoliciesCard initialDateRange={selectedDateRange} setCustom={setCustom} />
        </StyledTopPolicySection>
        <StyledDevicesRegisteredSection>
          <RegisteredCard instanceType="Devices" initialDateRange={selectedDateRange} setCustom={setCustom} />
        </StyledDevicesRegisteredSection>
        <StyledAppsRegisteredSection>
          <RegisteredCard instanceType="Apps" initialDateRange={selectedDateRange} setCustom={setCustom} />
        </StyledAppsRegisteredSection>
        <StyledPrivacyDevicesSection>
          <TopTenRiskScoreCard scoreType="Privacy" instanceType="Devices" initialDateRange={selectedDateRange} setCustom={setCustom} />
        </StyledPrivacyDevicesSection>
        <StyledSecurityDevicesSection>
          <TopTenRiskScoreCard scoreType="Security" instanceType="Devices" initialDateRange={selectedDateRange} setCustom={setCustom} />
        </StyledSecurityDevicesSection>
        <StyledAndroidIorsSection>
          <TopIorsCard platform="Android" initialDateRange={selectedDateRange} setCustom={setCustom} />
        </StyledAndroidIorsSection>
        <StyledIosIorsSection>
          <TopIorsCard platform="iOS" initialDateRange={selectedDateRange} setCustom={setCustom} />
        </StyledIosIorsSection>
      </StyledPanel>
    </KwContainer>
  );
}

export const Dashboard = withLDConsumer()(DashboardComponent);

const StyledPanel = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
  gridTemplateRows: 'auto auto auto auto auto auto auto auto',
  gridTemplateAreas: `
  'header header header header header header'
  'subHeader subHeader subHeader subHeader subHeader subHeader'
  'failedDeviceStat failedDeviceStat countriesContacted countriesContacted countriesContacted countriesContacted'
  'topPolicy topPolicy countriesContacted countriesContacted countriesContacted countriesContacted'
  'devicesRegistered devicesRegistered devicesRegistered appsRegistered appsRegistered appsRegistered'
  'privacyDevices privacyDevices privacyDevices securityDevices securityDevices securityDevices'
  'androidIors androidIors androidIors iosIors iosIors iosIors'`,
  gap: '44px 16px',
});

const StyledHeaderSection = styled('div')({
  gridArea: 'header',
});

const StyledSubHeaderSection = styled('div')({
  gridArea: 'subHeader',
});

const StyledFailedDeviceStatSection = styled('div')({
  gridArea: 'failedDeviceStat',
});

const StyledTopPolicySection = styled('div')({
  gridArea: 'topPolicy',
});

const StyledCountriesContactedSection = styled('div')({
  gridArea: 'countriesContacted',
});

const StyledDevicesRegisteredSection = styled('div')({
  gridArea: 'devicesRegistered',
});

const StyledAppsRegisteredSection = styled('div')({
  gridArea: 'appsRegistered',
});

const StyledPrivacyDevicesSection = styled('div')({
  gridArea: 'privacyDevices',
});

const StyledSecurityDevicesSection = styled('div')({
  gridArea: 'securityDevices',
});

const StyledAndroidIorsSection = styled('div')({
  gridArea: 'androidIors',
});

const StyledIosIorsSection = styled('div')({
  gridArea: 'iosIors',
});
