import { ErrorOutline, Close } from '@mui/icons-material';
import { ListItem, IconButton, ListItemText, Typography, styled, Card } from '@mui/material';
import { RuleEntity } from '@kw/service-definitions-rule';
import { useContext } from 'react';
import type { PolicyAction } from '../../pages/policies/getData';
import { KwFormErrorMessage } from '../../kw-ui-components/KwFormErrorMessage';
// eslint-disable-next-line import/extensions
import { PolicyProfileMode } from './enum/PolicyProfileMode.enum';
import { PolicyInfoContext } from './PolicyInfoContext';

interface IProps<T> {
  selectedList: T[];
  originalList: T[];
  title: string;
  removeAction: (id: number | string) => void;
  isHighlight: boolean;
  mode: PolicyProfileMode;
}

export const PolicySelectedListCard = ({
  selectedList = [],
  originalList = [],
  title = '',
  removeAction,
  isHighlight = false,
  mode,
}: IProps<RuleEntity | PolicyAction>) => {
  const { policyFormErrors } = useContext(PolicyInfoContext);

  const FullListNotice = () => {
    if (originalList.every(originalItem => selectedList.find(selectedItem => selectedItem.id === originalItem.id))) {
      return (
        <StyledNotice>
          <ErrorOutline /> All {title} added
        </StyledNotice>
      );
    }

    return <></>;
  };

  const saveInstructions = title === 'rules' && 'Include at least one rule';

  const ListCard = () => {
    const content =
      selectedList.length === 0 ? (
        <StyledTextWrapper>
          <Typography variant="h5Medium" data-testid={`selected-${title}-card`}>
            Select {title} that will be added to this policy
          </Typography>
          <Typography variant="subtitle1">{saveInstructions}</Typography>
        </StyledTextWrapper>
      ) : (
        selectedList.map(item => (
          <StyledListItem
            key={item.id}
            secondaryAction={
              [PolicyProfileMode.CREATE, PolicyProfileMode.EDIT].includes(mode) && (
                <IconButton edge="end" aria-label="remove" onClick={() => removeAction(item.id)}>
                  <Close />
                </IconButton>
              )
            }
            divider
            data-testid={`selected-${title}-item`}
          >
            <ListItemText primary={item.name} secondary={item.description} />
          </StyledListItem>
        ))
      );

    let component;

    if (selectedList.length === 0) {
      component = (
        <>
          <StyledEmptyCard variant="outlined" className={title === 'rules' && policyFormErrors.rules ? 'error' : ''}>
            {content}
          </StyledEmptyCard>
          <KwFormErrorMessage
            error={title === 'rules' && policyFormErrors.rules}
            reason="Unable to save policy. Policy must include at least one rule."
          />
        </>
      );
    } else {
      component = <StyledFilledCard>{content}</StyledFilledCard>;
    }

    if (isHighlight) {
      component = (
        <>
          <StyledHighlightCard variant="outlined" className={title === 'rules' && policyFormErrors.rules ? 'error' : ''}>
            {content}
          </StyledHighlightCard>
          <KwFormErrorMessage
            error={title === 'rules' && policyFormErrors.rules}
            reason="Unable to save policy. Policy must include at least one rule."
          />
        </>
      );
    }

    return component;
  };

  return (
    <>
      <FullListNotice />
      <ListCard />
    </>
  );
};

const StyledEmptyCard = styled(Card)({
  borderRadius: '8px',
  textAlign: 'center',
  border: '1px solid #979797',
  '&.error': {
    border: '2px solid #f44336',
  },
});

const StyledFilledCard = styled(StyledEmptyCard)({
  border: '2px solid #FAFAFA',
  boxShadow: '0 10px 14px 0 rgba(0,0,0,0.04)',
});

const StyledHighlightCard = styled(StyledEmptyCard)({
  border: '2px solid #487F87',
  '&.error': {
    border: '2px solid #f44336',
  },
});

const StyledListItem = styled(ListItem)({
  backgroundColor: '#FAFAFA',
});

const StyledNotice = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
});

const StyledTextWrapper = styled('div')({
  padding: '75px 0',
});
