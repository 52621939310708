import { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  MenuItem,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { PageLoader } from '../../PageLoader';
import { dateRange } from '../constants';

export function DashboardExpandableDataCard({
  initialDateRange = 7,
  title = '',
  data = [],
  minRowShow = 4,
  maxRowShow = 10,
  columns = [],
  functionToFetch,
  isLoading = false,
  rowIdField,
}: {
  initialDateRange: number;
  title: string;
  data: any[];
  minRowShow: number;
  maxRowShow: number;
  columns: any[];
  functionToFetch: ({ range }: { range: number }) => void;
  isLoading?: boolean;
  rowIdField: string;
}) {
  const [seeAll, setSeeAll] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState(initialDateRange);

  useEffect(() => {
    setSelectedDateRange(initialDateRange);
    functionToFetch({ range: initialDateRange });
  }, [initialDateRange]);

  const shownData = seeAll ? data.slice(0, maxRowShow) : data.slice(0, minRowShow);

  const handleDateRangeChange = event => {
    setSelectedDateRange(event.target.value);
    functionToFetch({ range: Number(event.target.value) });
  };

  return (
    <StyledCard>
      <CardHeader
        title={
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h4Medium">{title}</Typography>
            <Select variant="standard" value={selectedDateRange} onChange={handleDateRangeChange} disableUnderline>
              {dateRange.map(range => (
                <MenuItem key={range.value} value={range.value}>
                  <em>{range.label}</em>
                </MenuItem>
              ))}
            </Select>
          </Box>
        }
      />
      <CardContent>
        {isLoading && <PageLoader />}
        <TableContainer>
          <StyledTable aria-label="customized table">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <StyledTableCell key={column.label} align={column.align}>
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {shownData.map(row => (
                <TableRow key={row[rowIdField]}>
                  {columns.map(column => (
                    <TableCell key={`${column.label}`} align={column.align}>
                      {column.component ? column.component({ row, value: row[column.keyName] }) : row[column.keyName]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>
        </TableContainer>
      </CardContent>
      <StyledCardActions>
        <StyledClickableText onClick={() => setSeeAll(!seeAll)}>{seeAll ? 'See less' : 'See all'}</StyledClickableText>
      </StyledCardActions>
    </StyledCard>
  );
}

const StyledCard = styled(Card)({
  borderRadius: '8px',
  boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)',
  backgroundColor: '#FAFAFA',
  padding: '8px',
});

const StyledCardActions = styled(CardActions)({
  justifyContent: 'center',
});

const StyledTable = styled(Table)({
  backgroundColor: 'white',
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#EEEEEE',
    color: theme.palette.common.black,
    borderBottom: 'none',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledClickableText = styled(Typography)({
  cursor: 'pointer',
});
