import type { ApplicationVersion } from '@kw/device-service/dist/application/entities/applicationVersion.entity';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useApplicationSortParams } from '../../components/Applications/useApplicationSortParams';
import { ResetButton } from '../../components/ResetButton';
import { KwScoreBadge } from '../../kw-ui-components/KwRiskBadge';
import { TableRowLink } from '../../components/TableRowLink';
import { KwColumnSortHeader } from '../../kw-ui-components/KwColumnSortHeader';
import { KwContainer } from '../../kw-ui-components/KwContainer';
import { KwFilterWrapper } from '../../kw-ui-components/KwFilterWrapper';
import { KwMultiSelect, useKwMultiSelect } from '../../kw-ui-components/KwMultiSelect/KwMultiSelect';
import { KwRangeSelect, useKwRangeSelect } from '../../kw-ui-components/KwRangeSelect/KwRangeSelect';
import { KwSearchInput } from '../../kw-ui-components/KwSearchInput';
import { KwTable } from '../../kw-ui-components/KwTable';
import { KwTableBody } from '../../kw-ui-components/KwTableBody';
import { KwTableCell } from '../../kw-ui-components/KwTableCell';
import { KwTableHead } from '../../kw-ui-components/KwTableHead';
import { KwTableRow } from '../../kw-ui-components/KwTableRow';
import { KwPaginationRouter } from '../../kw-ui-components/Pagination/KwPaginationRouter';
import { KwRowsPerPage } from '../../kw-ui-components/Pagination/KwRowsPerPage';
import { useKwPagination } from '../../kw-ui-components/Pagination/useKwPagination';
import { KwTablePaginationWrapper } from '../../kw-ui-components/Pagination/KwTablePaginationWrapper';
import { PageLoader } from '../../PageLoader';
import { createQueryString } from '../../utils/createQueryString';
import { useFetchService } from '../../utils/fetchService';
import { useStickyResult } from '../../utils/useStickyResult';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { formatPlatform } from '../../utils/formatPlatform';

export const useAppVersions = (appParams, accessToken) => {
  const queryParams = createQueryString(appParams);

  const shouldFetch = appParams.riskIndicatorsLengthRange[0] !== 'undefined';

  const { data: appData, error: appsDataError } = useFetchService<{
    apps: ApplicationVersion[];
    total: number;
  }>(`/application/versions?${queryParams}`, accessToken, shouldFetch);
  const { apps, total } = useStickyResult(appData);
  const isLoading = !appData && !appsDataError;
  return { apps, total, appsDataError, isLoading };
};

export default function Applications() {
  const [accessToken = ''] = useLocalStorage('accessToken', '');

  const { page, pageSize, setPage, handleChangePage, handleChangeRowsPerPage } = useKwPagination(10);

  const { data: iorsLengthRangeData, error: iorsLengthRangeDataError } = useFetchService('/application/iors/length-range', accessToken);
  const { min, max } = useStickyResult(iorsLengthRangeData);
  const riskIndicatorsLengthRangeHook = useKwRangeSelect([min, max]);
  const securityRiskLevelRangeHook = useKwRangeSelect();
  const privacyRiskLevelRangeHook = useKwRangeSelect();
  const developersSelectHook = useKwMultiSelect();
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery] = useDebounce(searchQuery, 500);

  const {
    appNameOrder,
    toggleAppNameOrder,
    appIdOrder,
    toggleAppIdOrder,
    appOsOrder,
    toggleAppOsOrder,
    developerOrder,
    toggleDeveloperOrder,
    numberOfDevicesOrder,
    toggleNumberOfDevicesOrder,
    numberOfIorsOrder,
    toggleNumberOfIorsOrder,
    securityRiskLevelOrder,
    toggleSecurityRiskLevelOrder,
    privacyRiskLevelOrder,
    togglePrivacyRiskLevelOrder,
    resetToggles,
  } = useApplicationSortParams();

  const { apps, total, appsDataError, isLoading } = useAppVersions(
    {
      page: String(page),
      pageSize: String(pageSize),
      riskIndicatorsLengthRange: riskIndicatorsLengthRangeHook.value.map(String),
      securityRiskLevelRange: securityRiskLevelRangeHook.value.map(value => value).map(String),
      privacyRiskLevelRange: privacyRiskLevelRangeHook.value.map(value => value).map(String),
      developer: developersSelectHook.value,
      query: debouncedSearchQuery,
      appNameOrder,
      appIdOrder,
      appOsOrder,
      developerOrder,
      numberOfDevicesOrder,
      numberOfIorsOrder,
      securityRiskLevelOrder,
      privacyRiskLevelOrder,
    },
    accessToken,
  );

  const tableHeaders = [
    { title: 'App Name', onClick: toggleAppNameOrder, sortValue: appNameOrder },
    { title: 'App ID', onClick: toggleAppIdOrder, sortValue: appIdOrder },
    { title: 'Platform', onClick: toggleAppOsOrder, sortValue: appOsOrder },
    { title: 'Version' },
    {
      title: 'Developer',
      onClick: toggleDeveloperOrder,
      sortValue: developerOrder,
    },
    {
      title: '# of Devices',
      onClick: toggleNumberOfDevicesOrder,
      sortValue: numberOfDevicesOrder,
    },
    {
      title: '# of IORs',
      onClick: toggleNumberOfIorsOrder,
      sortValue: numberOfIorsOrder,
    },
    {
      title: 'Security Risk Level',
      onClick: toggleSecurityRiskLevelOrder,
      sortValue: securityRiskLevelOrder,
    },
    {
      title: 'Privacy Risk Level',
      onClick: togglePrivacyRiskLevelOrder,
      sortValue: privacyRiskLevelOrder,
    },
  ];

  const totalPageCount = Math.ceil(total / pageSize);

  if (appsDataError || iorsLengthRangeDataError) {
    if (appsDataError) console.error(appsDataError);
    if (iorsLengthRangeDataError) console.error(iorsLengthRangeDataError);

    return <div>failed to load</div>;
  }

  if (!apps || !iorsLengthRangeData) return <PageLoader />;

  const resetFiltersAndSorts = () => {
    riskIndicatorsLengthRangeHook.reset();
    securityRiskLevelRangeHook.reset();
    privacyRiskLevelRangeHook.reset();
    developersSelectHook.reset();
    setSearchQuery('');
    resetToggles();
    setPage(0);
  };

  return (
    <>
      {isLoading && <PageLoader />}
      <KwContainer>
        <KwFilterWrapper>
          <KwMultiSelect
            label="Developers"
            items={apps.map(({ application: { developer } }) => ({
              label: developer,
              value: developer,
            }))}
            {...developersSelectHook}
          />
          <KwRangeSelect
            label="# of IOR's"
            sliderAriaLabel="number-of-iors-range-slider"
            {...riskIndicatorsLengthRangeHook}
            handleChange={(...args) => {
              riskIndicatorsLengthRangeHook.handleChange(...args);
              setPage(0);
            }}
          />
          <KwRangeSelect
            label="Security Risk Level"
            sliderAriaLabel="Security Risk Level Slider"
            {...securityRiskLevelRangeHook}
            handleChange={(...args) => {
              securityRiskLevelRangeHook.handleChange(...args);
              setPage(0);
            }}
          />
          <KwRangeSelect
            label="Privacy Risk Level"
            sliderAriaLabel="Privacy Risk Level Slider"
            {...privacyRiskLevelRangeHook}
            handleChange={(...args) => {
              privacyRiskLevelRangeHook.handleChange(...args);
              setPage(0);
            }}
          />
          <ResetButton
            onClick={() => {
              resetFiltersAndSorts();
            }}
          />
          {/*
           * TODO: Range select sometimes throws runtime error "Cannot read properties of null".
           * Seems like a bug in the library, watch github issues
          */}
        </KwFilterWrapper>
        <div>
          <KwSearchInput value={searchQuery} onChange={setSearchQuery} placeholder="Search applications" />
          <KwTablePaginationWrapper>
            <KwRowsPerPage
              rowsPerPageOptions={[10, 20, 50, 100]}
              pageSize={pageSize}
              page={page}
              totalNumberOfEntries={total}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />

            {total > 10 ? <StyledPagination page={page} totalPageCount={totalPageCount} handleChangePage={handleChangePage} /> : null}
          </KwTablePaginationWrapper>
          <KwTable aria-label="Applications-Table">
            <KwTableHead>
              <KwTableRow>
                {tableHeaders.map(({ title, onClick, sortValue }) => (
                  <KwColumnSortHeader
                    key={title}
                    title={title}
                    onClick={() => {
                      onClick();
                      setPage(0);
                    }}
                    sortValue={sortValue}
                  />
                ))}
              </KwTableRow>
            </KwTableHead>
            <KwTableBody>
              {apps.map((appVersion: ApplicationVersion) => {
                const { application } = appVersion;

                return (
                  <TableRowLink
                    ariaLabel={`${application.title} row`}
                    key={appVersion.applicationId + appVersion.version + application.os + String(appVersion.riskIndicators.length)}
                    uniqueId={appVersion.applicationId}
                    href={`/applications/application-details?appId=${application.id}&appName=${application.title}&appVersion=${appVersion.version}&os=${application.os}`}
                  >
                    <KwTableCell selected={appNameOrder}>{application.title}</KwTableCell>
                    <KwTableCell selected={appIdOrder}>{application.id}</KwTableCell>
                    <KwTableCell selected={appOsOrder}>{formatPlatform(application.os)}</KwTableCell>
                    <KwTableCell>{appVersion.version}</KwTableCell>
                    <KwTableCell selected={developerOrder}>{application.developer}</KwTableCell>
                    <KwTableCell selected={numberOfDevicesOrder}>{appVersion.deviceCount}</KwTableCell>
                    <KwTableCell selected={numberOfIorsOrder}>{appVersion.riskIndicators.length}</KwTableCell>
                    <KwTableCell>
                      {typeof appVersion.securityRiskLevel === 'number' && (
                        <KwScoreBadge value={Math.floor(appVersion.securityRiskLevel)} />
                      )}
                    </KwTableCell>
                    <KwTableCell>
                      {typeof appVersion.privacyRiskLevel === 'number' && <KwScoreBadge value={appVersion.privacyRiskLevel} />}
                    </KwTableCell>
                  </TableRowLink>
                );
              })}
            </KwTableBody>
          </KwTable>
          {total > 10 ? (
            <KwTablePaginationWrapper>
              <KwRowsPerPage
                rowsPerPageOptions={[10, 20, 50, 100]}
                pageSize={pageSize}
                page={page}
                totalNumberOfEntries={total}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />

              <StyledPagination page={page} totalPageCount={totalPageCount} handleChangePage={handleChangePage} />
            </KwTablePaginationWrapper>
          ) : null}
        </div>
      </KwContainer>
    </>
  );
}

/** styles */

const StyledPagination = styled(KwPaginationRouter)`
  display: grid;
  place-items: center;
`;
