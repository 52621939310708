import { FormControl, MenuItem } from '@mui/material';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelect } from 'use-mui';
import { KwSelect, SelectArrowDown, SelectArrowUp, StyledOutlineInput } from '../KwSelect';
export const useKwRangeSelect = (range: [number, number] = [0, 10]) => {
  // this is needed because sometimes the range will be undefined initially during render
  range = useMemo(() => range, [range[0], range[1]]);

  const [value, setValue] = useState(range);

  const handleChange = newValue => {
    setValue(newValue);
  };

  // this is needed because sometimes the range will be undefined initially during render
  useEffect(() => {
    setValue([range[0], range[1]]);
  }, [range]);

  const reset = () => {
    setValue([range[0], range[1]]);
  };
  return {
    handleChange,
    value,
    range,
    reset,
  };
};

// TODO: there is a bug in mui where the slider will not display the knobs being dragged when using "onCommittedChange".
// useDrag is used here as a hack to fix this bug
const useDrag = updateValue => {
  const [isMouseDown, setIsMouseDown] = useState(false);
  const root = useRef(null);

  useEffect(() => {
    const setMouseDown = () => setIsMouseDown(true);
    document.body.addEventListener('mousedown', setMouseDown);

    return () => document.body.removeEventListener('mousedown', setMouseDown);
  }, [setIsMouseDown]);

  useEffect(() => {
    if (root.current) {
      const { current } = root;
      const handleMouseUp = (...props) => {
        updateValue(...props);
        current.removeEventListener('mouseup', handleMouseUp);
      };

      current.addEventListener('mouseup', handleMouseUp);
      return () => {
        current.removeEventListener('mouseup', handleMouseUp);
      };
    }
  }, [root.current, updateValue]);

  useEffect(() => {
    if (root.current) {
      const { current } = root;
      const setValue = (...props) => {
        updateValue(...props);
        document.body.removeEventListener('mouseup', setValue);
      };

      const handleMouseOut = () => {
        if (isMouseDown) {
          document.body.addEventListener('mouseup', setValue);
        }
      };

      const handleMouseIn = () => {
        document.body.removeEventListener('mouseup', setValue);
      };

      current.addEventListener('mouseout', handleMouseOut);
      current.addEventListener('mouseover', handleMouseIn);
      return () => {
        current.removeEventListener('mouseout', handleMouseOut);
        current.removeEventListener('mouseover', handleMouseIn);
      };
    }
  }, [root.current, isMouseDown, updateValue]);

  return root;
};
/**
 * TODO: there is a bug in mui where the slider will not display the knobs being dragged when using "onCommittedChange".
 * https://github.com/mui-org/material-ui/issues/27800
 * Currently a hack is implemented to bypass this issue using "onMouseUp".
 * should track this bug and pull latest and delete useDrag when fixed
 *
 */
type SelectUseKwRangeSelectProps = Pick<ReturnType<typeof useKwRangeSelect>, 'handleChange' | 'value' | 'range'>;
type PickerProps = SelectUseKwRangeSelectProps & { ariaLabel: string };
const Picker = ({ handleChange, value, range, ariaLabel, ...props }: PickerProps) => {
  const [trackedValue, setTrackedValue] = useState(value);
  const updateValue = useCallback(() => handleChange(trackedValue), [handleChange, trackedValue]);

  const rootRef = useDrag(updateValue);
  return (
    <Slider
      min={range[0]}
      max={range[1]}
      getAriaLabel={currentValue => (currentValue === trackedValue[0] ? `${ariaLabel}-minimum` : `${ariaLabel}-maximum`)}
      onChange={(_event, newValue: [number, number]) => {
        setTrackedValue(newValue);
      }}
      ref={rootRef}
      valueLabelDisplay="auto"
      value={trackedValue}
      {...props}
    />
  );
};
type KwRangeSelectProps = ReturnType<typeof useKwRangeSelect> & {
  label: string;
  sliderAriaLabel: string;
};
// TODO: make keyboard accessible https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_slider_role
export const KwRangeSelect = ({ label, handleChange, value, sliderAriaLabel, range = [0, 10] }: KwRangeSelectProps) => {
  const { open, handleOpen, handleClose } = useSelect({
    defaultValue: [],
  });
  const [min, max] = range;
  const id = _.camelCase(label);
  return (
    <FormControl>
      <StyledSelect
        displayEmpty
        label={<div id={id}>{label} select dropdown</div>}
        renderValue={() => <div>{label}</div>}
        input={<StyledOutlineInput />}
        onOpen={handleOpen}
        onClose={handleClose}
        IconComponent={_props => {
          const isAllInclusive = value[0] === min && value[1] === max;
          return (
            <>
              {!isAllInclusive ? (
                <SelectCounter>
                  {value[0]} - {value[1]}
                </SelectCounter>
              ) : (
                ''
              )}

              {open ? <SelectArrowUp /> : <SelectArrowDown />}
            </>
          );
        }}
      >
        <StyledMenuItem dense>
          <StyledSliderLabel>{value[0]}</StyledSliderLabel>
          <StyledPicker value={value} range={range} handleChange={handleChange} ariaLabel={sliderAriaLabel} />
          <StyledSliderLabel>{value[1]}</StyledSliderLabel>
        </StyledMenuItem>
      </StyledSelect>
    </FormControl>
  );
};

/** styles */

const StyledSelect = styled(props => (
  <KwSelect
    MenuProps={{
      PaperProps: {
        sx: {
          overflow: 'visible',
        },
      },
    }}
    {...props}
  />
))`` as typeof KwSelect;

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  justify-content: space-between;
  background-color: #fafafa !important;
  box-shadow: ${props => props.theme.elevation['2dp']};
  height: 63px;
  padding-left: 8px;
  padding-right: 8px;
  width: 200px;
  overflow: visible;
  &.Mui-selected {
    background-color: #fafafa;
    :hover {
      background-color: #fafafa;
    }
  }
`;

const StyledPicker = styled(Picker)`
  width: 118px;

  & .MuiSlider-thumb {
    width: 16px;
    height: 16px;
    background-color: white;
    border: solid 2px #1a4da4;
  }
  & .MuiSlider-track {
    border: none;
  }
`;

const StyledSliderLabel = styled('div')`
  width: 23px;
  font-size: 12px;
  text-align: center;
  padding-top: 3px;
`;

const SelectCounter = styled('div')`
  width: auto;
  height: 20px;
  border-radius: 12px;
  padding-left: 8px;
  padding-right: 8px;
  flex-shrink: 0;
  text-align: center;
  background-color: ${props => props.theme.palette.accent[400]};
  ${props => props.theme.typography.smallRegular as any}
  color: white;
`;
