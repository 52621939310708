import type { ApplicationVersion } from '@kw/device-service/dist/application/entities/applicationVersion.entity';
import { Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useAppVersions } from '../../pages/applications';
import { KwScoreBadge } from '../../kw-ui-components/KwRiskBadge';
import { KwTable } from '../../kw-ui-components/KwTable';
import { KwTableBody } from '../../kw-ui-components/KwTableBody';
import { KwTableCell } from '../../kw-ui-components/KwTableCell';
import { KwTableHead } from '../../kw-ui-components/KwTableHead';
import { KwTableRow } from '../../kw-ui-components/KwTableRow';
import { KwTablePaginationWrapper } from '../../kw-ui-components/Pagination/KwTablePaginationWrapper';
import { KwPaginationRouter } from '../../kw-ui-components/Pagination/KwPaginationRouter';
import { KwRowsPerPage } from '../../kw-ui-components/Pagination/KwRowsPerPage';
import { useKwPagination } from '../../kw-ui-components/Pagination/useKwPagination';
import { PageLoader } from '../../PageLoader';
import { useApplicationSortParams } from './useApplicationSortParams';
import { KwColumnSortHeader } from '../../kw-ui-components/KwColumnSortHeader';

interface IProps {
  accessToken: string;
  appName: string;
  appId: string;
  os: string;
}

export const AppVersionsTable = ({ accessToken, appName, appId, os }: IProps) => {
  const {
    appIdOrder,
    toggleAppIdOrder,
    developerOrder,
    toggleDeveloperOrder,
    numberOfDevicesOrder,
    toggleNumberOfDevicesOrder,
    numberOfIorsOrder,
    toggleNumberOfIorsOrder,
    securityRiskLevelOrder,
    toggleSecurityRiskLevelOrder,
    privacyRiskLevelOrder,
    togglePrivacyRiskLevelOrder,
  } = useApplicationSortParams();

  const { page, pageSize, setPage, handleChangePage, handleChangeRowsPerPage } = useKwPagination(10);

  const { apps, appsDataError, total } = useAppVersions(
    {
      page: String(page),
      pageSize: String(pageSize),
      riskIndicatorsLengthRange: ['0', '100'],
      securityRiskLevelRange: ['0', '10'],
      privacyRiskLevelRange: ['0', '10'],
      appId,
      appIdOrder,
      os,
      developerOrder,
      numberOfDevicesOrder,
      numberOfIorsOrder,
      securityRiskLevelOrder,
      privacyRiskLevelOrder,
    },
    accessToken,
  );

  if (appsDataError) {
    console.error(appsDataError);
    return <div>failed to load</div>;
  }

  if (!apps) {
    return <PageLoader />;
  }

  const versionTableHeaders = [
    { title: 'Version' },
    {
      title: 'Developer',
      onClick: toggleDeveloperOrder,
      sortValue: developerOrder,
    },
    { title: 'App ID', onClick: toggleAppIdOrder, sortValue: appIdOrder },
    {
      title: '# of Devices',
      onClick: toggleNumberOfDevicesOrder,
      sortValue: numberOfDevicesOrder,
    },
    {
      title: '# of IORs',
      onClick: toggleNumberOfIorsOrder,
      sortValue: numberOfIorsOrder,
    },
    {
      title: 'Security Risk Level',
      onClick: toggleSecurityRiskLevelOrder,
      sortValue: securityRiskLevelOrder,
    },
    {
      title: 'Privacy Risk Level',
      onClick: togglePrivacyRiskLevelOrder,
      sortValue: privacyRiskLevelOrder,
    },
  ];
  const totalPageCount = Math.ceil(total / pageSize);

  return (
    <StyledTableWrapper>
      <StyledTableTitle>
        <Typography variant="h4Medium">Versions of {appName}</Typography>
      </StyledTableTitle>
      <StyledTableTitleDivider orientation="horizontal" />
      {total > 10 ? (
        <KwTablePaginationWrapper>
          <KwRowsPerPage
            rowsPerPageOptions={[10, 20, 50, 100]}
            pageSize={pageSize}
            page={page}
            totalNumberOfEntries={total}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />

          <StyledPagination page={page} totalPageCount={totalPageCount} handleChangePage={handleChangePage} />
        </KwTablePaginationWrapper>
      ) : null}
      <KwTable aria-label={`Version table of application ${appName}`}>
        <KwTableHead>
          <KwTableRow>
            {versionTableHeaders.map(({ title, sortValue, onClick }) => (
              <KwColumnSortHeader
                key={title}
                title={title}
                onClick={() => {
                  onClick();
                  setPage(0);
                }}
                sortValue={sortValue}
              />
            ))}
          </KwTableRow>
        </KwTableHead>
        <KwTableBody>
          {apps.map((appVersion: ApplicationVersion) => {
            const { application } = appVersion;

            if (application.os === os) {
              return (
                <KwTableRow key={appVersion.applicationId + appVersion.version + String(appVersion.riskIndicators.length)}>
                  <KwTableCell>{appVersion.version}</KwTableCell>
                  <KwTableCell selected={developerOrder}>{application.developer}</KwTableCell>
                  <KwTableCell selected={appIdOrder}>{application.id}</KwTableCell>
                  <KwTableCell selected={numberOfDevicesOrder}>{appVersion.deviceCount}</KwTableCell>
                  <KwTableCell selected={numberOfIorsOrder}>{appVersion.riskIndicators.length}</KwTableCell>
                  <KwTableCell>
                    {typeof appVersion.securityRiskLevel === 'number' && <KwScoreBadge value={Math.floor(appVersion.securityRiskLevel)} />}
                  </KwTableCell>
                  <KwTableCell>
                    {typeof appVersion.privacyRiskLevel === 'number' && <KwScoreBadge value={appVersion.privacyRiskLevel} />}
                  </KwTableCell>
                </KwTableRow>
              );
            }
            return null;
          })}
        </KwTableBody>
      </KwTable>
      {total > 10 ? (
        <KwTablePaginationWrapper>
          <KwRowsPerPage
            rowsPerPageOptions={[10, 20, 50, 100]}
            pageSize={pageSize}
            page={page}
            totalNumberOfEntries={total}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <StyledPagination page={page} totalPageCount={totalPageCount} handleChangePage={handleChangePage} />
        </KwTablePaginationWrapper>
      ) : null}
    </StyledTableWrapper>
  );
};
/** styles */

const StyledTableWrapper = styled('div')`
  border-top: 1px solid ${props => props.theme.palette.greyOverride[300]};
`;

const StyledPagination = styled(KwPaginationRouter)`
  display: grid;
  place-items: center;
`;

const StyledTableTitleDivider = styled(Divider)`
  background-color: ${props => props.theme.palette.accent.primary};
`;

const StyledTableTitle = styled('div')`
  height: 66px;
  width: 100%;
  padding: 16px;
  background-color: ${props => props.theme.palette.greyOverride[50]};
`;
