import type { ApplicationVersion } from '@kw/device-service/dist/application/entities/applicationVersion.entity';
import type { DeviceState } from '@kw/device-service/dist/device/entities/deviceState.entity';
import type { Indicator as RiskIndicator } from '@kw/service-definitions-risk_score';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { GetDeviceStatePolicyStatusResponse } from '@kw/service-definitions-rule';
import { useApplicationSortParams } from '../../components/Applications/useApplicationSortParams';
import { KwScoreBadge } from '../../kw-ui-components/KwRiskBadge';
import { KwColumnSortHeader } from '../../kw-ui-components/KwColumnSortHeader';
import { RiskLevelPane } from '../../components/Devices/RiskLevelPane';
import { useInstalledApps } from '../../components/Devices/useInstalledApps';
import { KwContainer } from '../../kw-ui-components/KwContainer';
import { KwSearchInput } from '../../kw-ui-components/KwSearchInput';
import { KwTable } from '../../kw-ui-components/KwTable';
import { KwTableBody } from '../../kw-ui-components/KwTableBody';
import { KwTableCell } from '../../kw-ui-components/KwTableCell';
import { KwTableHead } from '../../kw-ui-components/KwTableHead';
import { KwTableRow } from '../../kw-ui-components/KwTableRow';
import { KwTablePaginationWrapper } from '../../kw-ui-components/Pagination/KwTablePaginationWrapper';
import { KwPaginationRouter } from '../../kw-ui-components/Pagination/KwPaginationRouter';
import { KwRowsPerPage } from '../../kw-ui-components/Pagination/KwRowsPerPage';
import { useKwPagination } from '../../kw-ui-components/Pagination/useKwPagination';
import { Link } from '../../Link';
import { PageLoader } from '../../PageLoader';
import { getMostRecentState } from '../../utils/getMostRecentState';
import { getOldestState } from '../../utils/getOldestState';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { AuthPermissions } from '../../auth/AuthPermissions';
import { useFetchService } from '../../utils/fetchService';
import { verifyAuthPermission } from '../../auth/VerifyAuthPermission';
import { createQueryString } from '../../utils/createQueryString';
import { getTriggeredPoliciesListString } from '../../utils/getTriggeredPoliciesListString';

export default function DeviceDetails() {
  const [accessToken = ''] = useLocalStorage('accessToken', '');

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const deviceId = searchParams.get('deviceId');
  const deviceName = searchParams.get('deviceName');

  const {
    appNameOrder,
    toggleAppNameOrder,
    appIdOrder,
    toggleAppIdOrder,
    developerOrder,
    toggleDeveloperOrder,
    numberOfIorsOrder,
    toggleNumberOfIorsOrder,
    securityRiskLevelOrder,
    toggleSecurityRiskLevelOrder,
    privacyRiskLevelOrder,
    togglePrivacyRiskLevelOrder,
  } = useApplicationSortParams();

  const { page, pageSize, setPage, handleChangePage, handleChangeRowsPerPage } = useKwPagination(10);

  const [searchQuery, setSearchQuery] = useState('');

  const { data: device, error: deviceDataError } = useFetchService(`/device/${deviceId}`, accessToken);

  const [currentDeviceStateId, setCurrentDeviceStateId] = useState('');
  const queryParamString = createQueryString({ deviceStateId: currentDeviceStateId });
  const shouldFetch = Boolean(currentDeviceStateId);
  const { data: response, error: policyStatusError } = useFetchService<GetDeviceStatePolicyStatusResponse>(
    `/rule/policies/device-state-policy-status?${queryParamString}`,
    accessToken,
    shouldFetch,
  );

  const triggeredPoliciesListString = getTriggeredPoliciesListString(response);

  let deviceSerial;
  if (device) {
    deviceSerial = device.serial;
  }

  const canListApps: boolean = verifyAuthPermission(accessToken, AuthPermissions.LIST_DEVICE_APPS);
  const { apps, appDataError, total } = useInstalledApps(
    canListApps,
    {
      page: String(page),
      pageSize: String(pageSize),
      query: searchQuery,
      installedDeviceIds: [deviceId],
      appNameOrder,
      appIdOrder,
      developerOrder,
      numberOfIorsOrder,
      securityRiskLevelOrder,
      privacyRiskLevelOrder,
    },
    accessToken,
  );

  const { apps: topSecurityScoreApps, appDataError: topSecurityScoreAppsError } = useInstalledApps(
    canListApps,
    {
      pageSize: 5,
      installedDeviceIds: [deviceId],
      privacyRiskLevelOrder: 'DESC',
    },
    accessToken,
  );

  const { apps: topPrivacyScoreApps, appDataError: topPrivacyScoreAppsError } = useInstalledApps(
    canListApps,
    {
      pageSize: 5,
      installedDeviceIds: [deviceId],
      securityRiskLevelOrder: 'DESC',
    },
    accessToken,
  );

  let latestDeviceState;
  if (device) {
    latestDeviceState = getMostRecentState<DeviceState>(device.states);
  }
  useEffect(() => {
    if (latestDeviceState) {
      setCurrentDeviceStateId(latestDeviceState.id);
    }
  }, [latestDeviceState]);

  if (appDataError || deviceDataError || topSecurityScoreAppsError || topPrivacyScoreAppsError) {
    if (appDataError) console.error(appDataError);
    if (deviceDataError) console.error(deviceDataError);
    if (topSecurityScoreAppsError) console.error(topSecurityScoreAppsError);
    if (topPrivacyScoreAppsError) console.error(topPrivacyScoreAppsError);

    return <div>failed to load</div>;
  }

  if (policyStatusError) {
    console.log('Unable to get policy status');
  }

  if (!device) {
    return <PageLoader />;
  }

  if (canListApps && (!apps || !device || !topSecurityScoreApps || !topPrivacyScoreApps)) {
    return <PageLoader />;
  }

  const oldestDeviceState = getOldestState<DeviceState>(device.states);
  const ownershipMap = {
    company: 'Company-Owned',
    personal: 'Personal-Owned',
  };

  const deviceMetaData = [
    ['OS Version', latestDeviceState.osVersion],
    ['IMEI', device.imei],
    ['Model', device.hardware.modelName],
    ['First Seen', dayjs(oldestDeviceState.createdDate).format('MM/DD/YYYY HH:mm:ss A')],
    ['OS Build Version', latestDeviceState.osBuildVersion],
    ['Serial Number', deviceSerial],
    ['Owner', ownershipMap[device.ownership]],
    ['Last Seen', dayjs(latestDeviceState.createdDate).format('MM/DD/YYYY HH:mm:ss A')],
  ];

  const tableHeaders = [
    { title: 'App Name', onClick: toggleAppNameOrder, sortValue: appNameOrder },
    { title: 'App ID', onClick: toggleAppIdOrder, sortValue: appIdOrder },
    { title: 'Version' },
    {
      title: 'Developer',
      onClick: toggleDeveloperOrder,
      sortValue: developerOrder,
    },
    {
      title: '# of IORs',
      onClick: toggleNumberOfIorsOrder,
      sortValue: numberOfIorsOrder,
    },
    {
      title: 'Security Risk Score',
      onClick: toggleSecurityRiskLevelOrder,
      sortValue: securityRiskLevelOrder,
    },
    {
      title: 'Privacy Risk Score',
      onClick: togglePrivacyRiskLevelOrder,
      sortValue: privacyRiskLevelOrder,
    },
  ];
  const { riskIndicators } = latestDeviceState;

  const securityIorsCount = (riskIndicators as unknown as RiskIndicator[]).filter(ind => ind.isSecurityConcern).length;

  const privacyIorsCount = (riskIndicators as unknown as RiskIndicator[]).filter(ind => ind.isPrivacyConcern).length;

  const totalPageCount = Math.ceil(total / pageSize);

  return (
    <StyledKwContainer>
      <StyledBackButton href="/devices">
        <StyledArrowBack />
        <StyledBackText>Back to Device List</StyledBackText>
      </StyledBackButton>
      <StyledContent>
        <StyledMetaDataTable aria-label="device-meta-data-table">
          <tbody>
            <StyledMetaDataRow>
              {deviceMetaData.map(([title, dataValue]) => (
                <td key={title + dataValue}>
                  <StyledMetaDataTitle>{title}</StyledMetaDataTitle>
                  <StyledMetaDataText>{dataValue}</StyledMetaDataText>
                </td>
              ))}
            </StyledMetaDataRow>
          </tbody>
        </StyledMetaDataTable>

        <RiskLevelPane
          title="Security Risk Score"
          canListApps={canListApps}
          score={Math.floor(latestDeviceState.securityRiskLevel)}
          numberOfIors={securityIorsCount}
          topRiskyApps={
            canListApps &&
            topSecurityScoreApps.map(({ application }) => [
              application.title,
              device.os === 'android' ? <StyledAndroidIcon /> : <StyledAppleIcon />,
            ])
          }
        />

        <RiskLevelPane
          title="Privacy Risk Score"
          canListApps={canListApps}
          // @ts-ignore
          score={latestDeviceState.privacyRiskLevel}
          numberOfIors={privacyIorsCount}
          topRiskyApps={
            canListApps &&
            topPrivacyScoreApps.map(({ application }) => [
              application.title,
              device.os === 'android' ? <StyledAndroidIcon /> : <StyledAppleIcon />,
            ])
          }
          triggeredPoliciesListString={triggeredPoliciesListString}
        />

        {canListApps && (
          <StyledTableWrapper>
            <StyledTableTitle>
              <Typography component="h2" variant="h4Medium">
                Apps on {deviceName}
              </Typography>
            </StyledTableTitle>
            <StyledKwSearchInput value={searchQuery} onChange={setSearchQuery} placeholder={`Search apps on ${deviceName}`} />
            <KwTablePaginationWrapper>
              <KwRowsPerPage
                rowsPerPageOptions={[10, 20, 50, 100]}
                pageSize={pageSize}
                page={page}
                totalNumberOfEntries={total}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />

              {total > 10 ? <StyledPagination page={page} totalPageCount={totalPageCount} handleChangePage={handleChangePage} /> : null}
            </KwTablePaginationWrapper>

            <KwTable aria-label={`Installed Applications on ${deviceName} Table`}>
              <KwTableHead>
                <KwTableRow>
                  {tableHeaders.map(({ title, sortValue, onClick }) => (
                    <KwColumnSortHeader
                      key={title}
                      title={title}
                      onClick={() => {
                        onClick();
                        setPage(0);
                      }}
                      sortValue={sortValue}
                    />
                  ))}
                </KwTableRow>
              </KwTableHead>
              <KwTableBody>
                {apps.map((appVersion: ApplicationVersion) => {
                  const { application } = appVersion;

                  return (
                    <KwTableRow key={appVersion.applicationId + appVersion.version + application.os}>
                      <KwTableCell selected={appNameOrder}>{application.title}</KwTableCell>
                      <KwTableCell selected={appIdOrder}>{application.id}</KwTableCell>
                      <KwTableCell>{appVersion.version}</KwTableCell>
                      <KwTableCell selected={developerOrder}>{application.developer}</KwTableCell>
                      <KwTableCell selected={numberOfIorsOrder}>{appVersion.riskIndicators.length}</KwTableCell>
                      <KwTableCell>
                        {typeof appVersion.securityRiskLevel === 'number' && (
                          <KwScoreBadge value={Math.floor(appVersion.securityRiskLevel)} />
                        )}
                      </KwTableCell>
                      <KwTableCell>
                        {typeof appVersion.privacyRiskLevel === 'number' && <KwScoreBadge value={appVersion.privacyRiskLevel} />}
                      </KwTableCell>
                    </KwTableRow>
                  );
                })}
              </KwTableBody>
            </KwTable>
            {total > 10 ? (
              <KwTablePaginationWrapper>
                <KwRowsPerPage
                  rowsPerPageOptions={[10, 20, 50, 100]}
                  pageSize={pageSize}
                  page={page}
                  totalNumberOfEntries={total}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <StyledPagination page={page} totalPageCount={totalPageCount} handleChangePage={handleChangePage} />
              </KwTablePaginationWrapper>
            ) : null}
          </StyledTableWrapper>
        )}
      </StyledContent>
    </StyledKwContainer>
  );
}
/** styles */

const StyledBackButton = styled(Link)`
  display: flex;
  height: 30px;
  padding-left: 18px;
  padding-right: 22px;
  column-gap: 11px;
  width: 177px;
  border-radius: 4px;
  margin-bottom: 7px;

  align-items: center;
  text-decoration: none;
  color: ${props => props.theme.palette.greyOverride[800]};

  &:hover {
    background-color: ${props => props.theme.palette.greyOverride[200]};
  }
`;

const StyledAppleIcon = styled(AppleIcon)`
  font-size: 32px;
  color: #a2aaad;
`;

const StyledAndroidIcon = styled(AndroidIcon)`
  font-size: 32px;
  color: #a4c639;
`;

const StyledBackText = styled('div')`
  font-size: 13px;
  line-height: 13px;
`;

const StyledArrowBack = styled(ArrowBackIcon)`
  width: 16px;
`;

const StyledTableTitle = styled('div')`
  height: 66px;
  width: 100%;
  padding: 16px;
  background-color: ${props => props.theme.palette.greyOverride[50]};
`;

const StyledKwContainer = styled(KwContainer)`
  padding: 8px 32px;
`;

const StyledContent = styled('div')`
  display: grid;
  grid-template-areas:
    'metadata' auto
    'security' auto
    'privacy.' auto
    'table' auto
    / 100%;

  grid-row-gap: 24px;
  margin-bottom: 35px;
`;

const StyledMetaDataTable = styled('table')`
  padding: 24px;
  width: 100%;
  background-color: ${props => props.theme.palette.greyOverride[50]};
`;

const StyledMetaDataRow = styled('tr')`
  display: grid;
  grid-template-columns: repeat(4, max(21%));
  row-gap: 24px;
`;

const StyledMetaDataTitle = styled('div')`
  font-size: 10px;
  line-height: 14px;
  color: '#818181';
`;

const StyledKwSearchInput = styled(KwSearchInput)`
  & div {
    border-radius: 0;
  }
`;

const StyledMetaDataText = styled('div')`
  font-size: 14px;
  line-height: 21px;
  color: #424242;
`;

const StyledTableWrapper = styled('div')`
  border-top: 1px solid ${props => props.theme.palette.greyOverride[300]};
`;

const StyledPagination = styled(KwPaginationRouter)`
  display: grid;
  place-items: center;
`;
