import { Card, CardContent, InputBase, Link, Tooltip, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { jwtDecode } from 'jwt-decode';
import { KwButton } from '../../kw-ui-components/KwButton/KwButton';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { useFetchService } from '../../utils/fetchService';
import { sendHttpRequest } from '../../utils/network.service';
import { PageLoader } from '../../PageLoader';
import { AuthPermissions } from '../../auth/AuthPermissions';
import { verifyAuthPermission } from '../../auth/VerifyAuthPermission';
// eslint-disable-next-line import/extensions
import { SettingsMode } from './enum/SettingsMode.enum';

interface IntegrationsCardProps {
  signInUrl: string;
  signInUrlPlaceholder: string;
  setSignInUrl: React.Dispatch<React.SetStateAction<string>>;
  managementHint: { [key: string]: string };
  managementHintPlaceholder: { [key: string]: string };
  isAdmin: boolean;
  setManagementHint: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
  updateIdpCredentials: () => void;
  resetInputValues: () => void;
  mode: SettingsMode;
  setMode: React.Dispatch<React.SetStateAction<SettingsMode>>;
}

const IntegrationsCard = ({
  signInUrl,
  signInUrlPlaceholder,
  setSignInUrl,
  managementHint,
  managementHintPlaceholder,
  isAdmin,
  setManagementHint,
  updateIdpCredentials,
  resetInputValues,
  mode,
  setMode,
}: IntegrationsCardProps) => {
  const isEditMode = mode === SettingsMode.EDIT;
  const isReadMode = mode === SettingsMode.READ;
  const [accessToken = ''] = useLocalStorage('accessToken', '');
  const { organizationId } = jwtDecode(accessToken)['https://krwr.net/app_metadata'];
  const [isCopied, setIsCopied] = useState(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);

  const enrollmentLink = `${window.location.origin}/#/okta-troubleshooting?orgId=${organizationId}`;

  const handleCopyLink = async () => {
    await navigator.clipboard.writeText(enrollmentLink);
    setIsCopied(true);
  };

  useEffect(() => {
    // Resets 'isCopied' state after 3 seconds
    if (isCopied) {
      const timeout = setTimeout(() => {
        setIsCopied(false);
      }, 3000);
      return () => clearTimeout(timeout);
    }
    return undefined;
  }, [isCopied]);

  useEffect(() => {
    if (signInUrl.length || managementHint.ios.length || managementHint.android.length) {
      setIsSaveButtonDisabled(false);
    }
    if (signInUrl.length === 0 && managementHint.ios.length === 0 && managementHint.android.length === 0) {
      setIsSaveButtonDisabled(true);
    }
  }, [managementHint, signInUrl]);

  return (
    <StyledCard>
      <StyledCardContent>
        <StyledTypography variant="h4Bold">Okta Endpoint Management Attestation</StyledTypography>
        <StyledTypography variant="bodyRegular">
          Configure Endpoint Management Attestation for Mobile Devices using the Okta Verify App. See Okta documentation for Endpoint
          management{' '}
          <a
            href="https://help.okta.com/oie/en-us/content/topics/identity-engine/devices/config-mobile.htm"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </StyledTypography>
        <StyledTypography variant="h5Bold">Management Hints</StyledTypography>
        {isAdmin && isEditMode ? (
          <>
            <StyledTypography variant="bodyRegular">
              Copy the Secret Key you obtained when configuring management attestation for a Device Management Platform and paste it in the
              corresponding platform field below. Saving your key here will auto-publish Okta Verify as a Q-Scout Managed App. Your key will
              be used as the Management Hint to configure the Q-Scout deployment of the Okta Verify app.
            </StyledTypography>
            <StyledTypography variant="bodyBold">iOS</StyledTypography>
            <StyledInput
              placeholder={managementHintPlaceholder.ios}
              onChange={e => {
                setManagementHint({ ...managementHint, ios: e.target.value });
              }}
              inputProps={{ 'aria-label': 'managementHintIos' }}
              className="idp-form-input"
            />
            <StyledTypography variant="bodyBold">Android</StyledTypography>
            <StyledInput
              placeholder={managementHintPlaceholder.android}
              onChange={e => {
                setManagementHint({ ...managementHint, android: e.target.value });
              }}
              inputProps={{ 'aria-label': 'managementHintAndroid' }}
              className="idp-form-input"
            />
          </>
        ) : (
          <>
            <StyledTypography variant="bodyBold">iOS</StyledTypography>
            <StyledTypography>{managementHintPlaceholder.ios}</StyledTypography>
            <StyledTypography variant="bodyBold">Android</StyledTypography>
            <StyledTypography>{managementHintPlaceholder.android}</StyledTypography>
          </>
        )}

        <StyledTypography variant="h5Bold">Okta Sign-in URL</StyledTypography>
        {isAdmin && isEditMode ? (
          <>
            <StyledTypography variant="bodyRegular">
              Optionally input your organization&apos;s Okta sign-in URL. This URL will be used in the configuration of the managed version
              of Okta Verify deployed from Q-Scout so that Device Owners don&apos;t have to enter it when they add an Okta Verify account.
              Your Okta sign-in URL can be found in the drop down under your Username in the upper right of your Okta Admin Console. The URL
              is generally formatted like <strong>example.okta.com</strong>, but can be in other formats.
            </StyledTypography>
            <StyledInput
              placeholder={signInUrlPlaceholder}
              onChange={e => {
                setSignInUrl(e.target.value);
              }}
              inputProps={{ 'aria-label': 'signInUrl' }}
              className="idp-form-input"
            />
          </>
        ) : (
          <StyledTypography>{signInUrlPlaceholder}</StyledTypography>
        )}

        <StyledTypography variant="h5Bold">Enrollment Link</StyledTypography>
        <StyledTypography variant="bodyRegular">
          Copy this URL to use as your Enrollment Link under each platform configured in the Security -&gt; Device Integrations -&gt;
          Endpoint management section of your Okta Console. See Okta documentation for Endpoint management.
        </StyledTypography>
        <Tooltip title={isCopied ? 'Copied!' : 'Copy Enrollment Link'} placement="right">
          <StyledCopyLinkSection onClick={handleCopyLink}>
            <ContentCopyIcon />
            <span>{enrollmentLink}</span>
          </StyledCopyLinkSection>
        </Tooltip>
        <StyledButtonContainer>
          {isAdmin && isEditMode ? (
            <>
              <StyledLink onClick={resetInputValues}>Cancel</StyledLink>
              <StyledKwButton variant="filled" onClick={updateIdpCredentials} disabled={isSaveButtonDisabled}>
                Save
              </StyledKwButton>
            </>
          ) : (
            <>
              {isAdmin && isReadMode ? (
                <StyledKwButton variant="filled" onClick={() => setMode(SettingsMode.EDIT)}>
                  Edit
                </StyledKwButton>
              ) : (
                <StyledKwButton disabled variant="filled">
                  Save
                </StyledKwButton>
              )}
            </>
          )}
        </StyledButtonContainer>
      </StyledCardContent>
    </StyledCard>
  );
};

export const useIntegrations = (accessToken: string) => {
  const { data: idpApiCredentials, error: idpError, mutate } = useFetchService('/organizations/identity-providers', accessToken);
  const isLoading = !idpApiCredentials && !idpError;
  return { idpApiCredentials, idpError, isLoading, mutate };
};

export function Integrations() {
  const [accessToken = ''] = useLocalStorage('accessToken', '');
  const [signInUrl, setSignInUrl] = useState('');
  const [managementHint, setManagementHint] = useState({ ios: '', android: '' });
  const [mode, setMode] = useState(SettingsMode.READ);

  const { enqueueSnackbar } = useSnackbar();
  const { idpApiCredentials = {}, idpError, isLoading, mutate } = useIntegrations(accessToken);

  const canUpdateOrg = verifyAuthPermission(accessToken, AuthPermissions.UPDATE_ORG);

  // hardcoded for now until additional idp support is added
  const idpSource = 'Okta';

  const updateIdentityProviderCredentials = async () => {
    const method = idpApiCredentials.length ? 'PUT' : 'POST';
    const { ios, android } = managementHint;

    const body = { idpSource };

    if (ios.length) {
      Object.assign(body, { iosManagementHint: ios });
    }

    if (android.length) {
      Object.assign(body, { androidManagementHint: android });
    }

    if (signInUrl.length) {
      Object.assign(body, { signInUrl });
    }

    await sendHttpRequest({
      path: `${process.env.ORGANIZATION_SERVICE_URL}/organizations/identity-providers`,
      method,
      body,
      successMessage: 'Successfully updated Okta Verify configuration',
      errorMessage: 'Error updating Okta Verify configuration',
      enqueueSnackbar,
    });

    mutate();
    resetInputValues();
  };

  const resetInputValues = () => {
    setSignInUrl('');
    setManagementHint({ ios: '', android: '' });
    setMode(SettingsMode.READ);
  };

  if (idpError) {
    return <div>failed to load</div>;
  }

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : (
        <IntegrationsCard
          signInUrl={signInUrl}
          signInUrlPlaceholder={idpApiCredentials[0]?.signInUrl ?? ''}
          setSignInUrl={setSignInUrl}
          managementHint={managementHint}
          managementHintPlaceholder={{
            ios: idpApiCredentials[0]?.iosManagementHint ?? '',
            android: idpApiCredentials[0]?.androidManagementHint ?? '',
          }}
          setManagementHint={setManagementHint}
          updateIdpCredentials={updateIdentityProviderCredentials}
          resetInputValues={resetInputValues}
          isAdmin={canUpdateOrg}
          mode={mode}
          setMode={setMode}
        />
      )}
    </>
  );
}

const StyledCard = styled(Card)`
  width: 40%;
  min-width: 300px;
`;

const StyledCardContent = styled(CardContent)`
  background-color: #fafafa;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  margin: 14px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &:nth-of-type(1) {
    color: #487f87;
  }
`;

const StyledInput = styled(InputBase)`
  width: 100%;
  background-color: #ffffff;
  border: 1px solid rgb(198, 198, 198);
  border-radius: 5px;
  padding: 4px;
  &::before {
    border-bottom: none;
  }
`;

const StyledTypography = styled(Typography)`
  margin: 20px 0;
  display: block;
  &:nth-of-type(3),
  :nth-of-type(4) {
    margin: 10px 0;
  }
`;

const StyledButtonContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  column-gap: 14px;
  margin-top: 10px;
`;

const StyledCopyLinkSection = styled('div')`
  display: flex;
  align-items; center;
  column-gap: 6px;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
  span:hover {
    text-decoration: underline;
  }
`;

const StyledKwButton = styled(KwButton)`
  width: 90px;
  height: 48px;
  padding: 8px 30px;
`;
