import { Routes, Route } from 'react-router-dom';
import Layout from '../../components/Layout';
import { ManagedAppsInfoContext, ManagedAppsInfoHook } from '../../components/ManagedApps/ManagedAppInfoContext';
import ManagedAppsTable from '../../components/ManagedApps/ManagedAppsTable';
import ManagedAppsSearch from '../../components/ManagedApps/ManagedAppsSearch';
import { ManagedAppsProfilePage } from '../../components/ManagedApps/ManagedAppsProfilePage';
// eslint-disable-next-line import/extensions
import { ManagedAppsProfileMode } from '../../components/ManagedApps/enum/ManagedAppsProfileMode.enum';

export default function ManagedApps() {
  const { platform, setPlatform, mode, setMode, app, setApp, searchHistory, setSearchHistory } = ManagedAppsInfoHook();

  return (
    <ManagedAppsInfoContext.Provider
      value={{
        platform,
        setPlatform,
        mode,
        setMode,
        app,
        setApp,
        searchHistory,
        setSearchHistory,
      }}
    >
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<ManagedAppsTable />} />
          <Route path="/search" element={<ManagedAppsSearch />} />
          <Route path="/add" element={<ManagedAppsProfilePage mode={ManagedAppsProfileMode.ADD} />} />
          <Route path="/edit/:managedAppId" element={<ManagedAppsProfilePage mode={ManagedAppsProfileMode.EDIT} />} />
        </Route>
      </Routes>
    </ManagedAppsInfoContext.Provider>
  );
}
