import CloseIcon from '@mui/icons-material/Close';
import { Card, Divider } from '@mui/material';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { KwScoreGauge } from '../../kw-ui-components/KwScoreGauge/KwScoreGauge';

type RiskyScorePaneProps = {
  title: string;
  score: number;
  numberOfIors: number;
  topIors: string[];
};

export const IorRiskPane = ({ title, score, numberOfIors, topIors }: RiskyScorePaneProps) => {
  const [isReadMore, setReadMore] = useState(false);
  const numDisplayed = 2;
  const topDisplayedIors = topIors
    .map(ior => `"${ior}"`)
    .slice(0, numDisplayed)
    .join(', ');

  return (
    <StyledScorePane>
      <StyledScoreSummary>
        <StyledScoreLabel>{title}</StyledScoreLabel>
        <KwScoreGauge value={score} />
        <StyledIorCount>
          <div>
            Found {numberOfIors} IOR{numberOfIors === 0 ? '' : 's'}
          </div>
        </StyledIorCount>
      </StyledScoreSummary>
      <StyledRiskLevelDivider orientation="vertical" variant="middle" />
      <StyledTopRiskIors>
        <StyledTopRiskAppsLabel>Top IORs</StyledTopRiskAppsLabel>
        <StyledTopIorsList variant="bodyRegular">
          {topDisplayedIors}
          {topIors.length > numDisplayed ? (
            <StyledSeeAllIorsButton onClick={() => setReadMore(true)} variant="text">
              See all {topIors.length}
            </StyledSeeAllIorsButton>
          ) : null}
          <StyledModal
            aria-label="application-ior-list"
            aria-details="Indicators of risk found in application"
            open={isReadMore}
            onClose={() => setReadMore(false)}
          >
            <StyledModalCard>
              <StyledModalHeader>
                <Typography variant="h4Medium">App IORs</Typography>
                <StyledCloseButton role="button" aria-label="Close modal" onClick={() => setReadMore(false)} />
              </StyledModalHeader>
              <Divider orientation="horizontal" />
              <Typography variant="bodyRegular">
                {topIors.map(ior => (
                  <div key={ior}>&ldquo;{ior}&rdquo;</div>
                ))}
              </Typography>
            </StyledModalCard>
          </StyledModal>
        </StyledTopIorsList>
      </StyledTopRiskIors>
    </StyledScorePane>
  );
};

/** styles */

const StyledTopRiskIors = styled('div')`
  display: flex;
  flex-direction: column;
  padding-left: 21px;
  padding-right: 21px;
  padding-top: 14px;
  padding-bottom: 15px;
  width: 100%;
`;
const StyledModalHeader = styled('div')`
  display: flex;
  justify-content: space-between;
`;
const StyledCloseButton = styled(CloseIcon)`
  cursor: pointer;
`;
const StyledTopIorsList = styled(Typography)``;

const StyledSeeAllIorsButton = styled(Button)`
  text-transform: none;
  display: inline-flex;
  padding: 0 0 0 5px;
`;

const StyledTopRiskAppsLabel = styled('div')`
  color: ${props => props.theme.typography.tinyMedium.color};
  font-size: ${props => props.theme.typography.tinyMedium.fontSize};
  line-height: ${props => props.theme.typography.tinyMedium.lineHeight};
`;

const StyledScoreLabel = styled('div')`
  grid-area: title;
  font-size: 14px;
  line-height: 21px;
  color: ${props => props.theme.palette.text.primary};
`;
const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .MuiPaper-root {
    box-shadow: 0 2px 4px rgba(0 0 0 /20%), 0 1px 10px rgba(0 0 0 / 12%), 0 4px 5px rgba(0 0 0 / 12%);
  }
`;
const StyledModalCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 7px;
  padding: 18px 16px;
  width: 540px;
`;
const StyledBackdrop = styled('div')`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const StyledScore = styled('div')`
  grid-area: score;
  transform: translateY(6px);
  font-size: 14px;
  line-height: 21px;
  color: ${props => props.theme.palette.text.primary};
`;

const StyledIorCount = styled('div')`
  grid-area: count;
  font-size: ${props => props.theme.typography.smallRegular.fontSize};
  color: ${props => props.theme.palette.greyOverride.secondary};
`;

const StyledScorePane = styled('div')`
  display: flex;
  height: 100%;
  background-color: ${props => props.theme.palette.greyOverride[100]};
`;

const StyledScoreSummary = styled('div')`
  padding: 16px;
  display: grid;
  width: 240px;
  grid-template:
    'title score' auto
    'count .....' auto
    'meter .....' auto
    / 135px auto;
`;

const StyledRiskLevelDivider = styled(props => <Divider flexItem {...props} />)`
  background-color: ${props => props.theme.palette.greyOverride[300]};
  margin-bottom: 15px;
  margin-top: 15px;
` as typeof Divider;

const EllipsisType = styled(Typography)`
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
` as typeof Typography;
