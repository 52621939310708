import { Chip, Dialog, DialogContent, Divider, InputBase, Typography } from '@mui/material';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import { ChangeEvent, ClipboardEvent, Dispatch, KeyboardEvent, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useMatchMutate } from '../../utils/useMatchMutate';
import { KwButton } from '../../kw-ui-components/KwButton/KwButton';
import { sendHttpRequest } from '../../utils/network.service';
import { KwFormErrorMessage } from '../../kw-ui-components/KwFormErrorMessage';
// eslint-disable-next-line import/extensions
import { DeviceOwnerStatus } from './enum/DeviceOwnerKeyMaps.enum';
import { useFetchService } from '../../utils/fetchService';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { useStickyResult } from '../../utils/useStickyResult';
import { validateEmail, globalValidEmailPattern } from '../../utils/emailValidation';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  setOwnerStatus?: Dispatch<SetStateAction<(typeof DeviceOwnerStatus)[keyof typeof DeviceOwnerStatus]>>;
  setTab?: Dispatch<SetStateAction<number>>;
}

export const AddDeviceOwnerModal = ({ isOpen, onClose, setOwnerStatus, setTab }: IProps) => {
  const [accessToken = ''] = useLocalStorage('accessToken', '');
  const [emailInputValue, setEmailInputValue] = useState('');
  const [emails, setEmails] = useState([]);
  const [emailInputErrors, setEmailInputErrors] = useState([]);
  const [existingDeviceOwners, setExistingDeviceOwners] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const matchMutate = useMatchMutate();

  // fetches unenrolled + pending device owners for validation
  const { data: deviceOwnersData, error: deviceOwnersError } = useFetchService(
    `/organizations/deviceowners/search?q=${encodeURIComponent('unenrolledAt:is:null')}`,
    accessToken,
  );

  const { deviceOwners } = useStickyResult(deviceOwnersData);

  useMemo(() => {
    if (deviceOwners) {
      setExistingDeviceOwners(
        deviceOwners.map(o => {
          const status = !o.inviteAcceptedAt ? DeviceOwnerStatus.PENDING : DeviceOwnerStatus.ENROLLED;
          return { email: o.email, status };
        }),
      );
    }
  }, [deviceOwners]);

  const inviteDeviceOwnersToOrg = async () => {
    let path: string;
    let body: { email: string; firstName?: string; lastName?: string } | { email: string }[];

    const currentEmailInputVal = emailInputValue.toLowerCase().trim();

    // if chip isn't added because user entered single email without delimiter or keys to trigger chip creation
    if (emails.length === 0 && emailInputValue.length && isValid(currentEmailInputVal)) {
      path = `${process.env.ORGANIZATION_SERVICE_URL}/organizations/deviceowners`;
      body = {
        email: currentEmailInputVal,
        firstName,
        lastName,
      };
    } else if (emails.length && emailInputValue.length && isValid(currentEmailInputVal)) {
      path = `${process.env.ORGANIZATION_SERVICE_URL}/organizations/deviceowners/bulk`;
      body = [...emails, currentEmailInputVal].map(email => ({ email }));
    } else if (emails.length === 1) {
      path = `${process.env.ORGANIZATION_SERVICE_URL}/organizations/deviceowners`;
      body = {
        email: emails[0],
        firstName,
        lastName,
      };
    } else if (emails.length > 1) {
      path = `${process.env.ORGANIZATION_SERVICE_URL}/organizations/deviceowners/bulk`;
      body = emails.map(email => ({ email }));
    } else {
      return;
    }

    await sendHttpRequest({
      path,
      method: 'POST',
      body,
      successMessage: 'Successfully invited device owner(s)',
      errorMessage: 'Error inviting device owner(s)',
      enqueueSnackbar,
    });
    // load pending tab
    setOwnerStatus(DeviceOwnerStatus.PENDING);
    setTab(1);
    // reset ui defaults
    setIsLoading(false);
    setEmails([]);
    setEmailInputValue('');
    setEmailInputErrors([]);
    setFirstName('');
    setLastName('');
    onClose();
    matchMutate(/\/organizations\/deviceowners\/search.?page=/);
  };

  useEffect(() => {
    // disable first and last name inputs if more than one email has been added
    if (emails.length > 1) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
    // enforce maximum of 50 emails per submission
    if (emails.length && emails.length > 50) {
      setEmails(emails.slice(0, 50));
      setEmailInputErrors([
        'You have reached the maximum number of emails for one submission.  Please add these emails and click +Add again to continue adding more.',
      ]);
    }
  }, [emails]);

  const handleAddEmails = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailInputValue(e.target.value);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (['Tab', ',', ' '].includes(e.key) && emailInputValue !== '') {
      e.preventDefault();

      const email = emailInputValue.toLowerCase().trim();

      if (email && isValid(email)) {
        setEmails([...emails, email]);
        setEmailInputValue('');
      }
    }

    if (['Backspace'].includes(e.key)) {
      if (emails.length && emailInputValue === '') {
        setEmails([...emails.slice(0, -1)]);
        setEmailInputErrors([]);
      }
      setEmailInputErrors([]);
    }
  };

  const handleSubmit = async (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      const email = emailInputValue.toLowerCase().trim();

      const validEmailInput = email && isValid(email);

      if (emails.length || validEmailInput) {
        await inviteDeviceOwnersToOrg();
      }
    }
  };

  const handleDelete = (toBeRemoved: string) => {
    setEmails(emails.filter(email => email !== toBeRemoved));
  };

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();

    const paste = e.clipboardData.getData('text').toLowerCase();
    const validEmails = paste.match(globalValidEmailPattern);

    if (!validEmails) return;

    // trim white space, and filter out empty strings (user pastes list with leading or trailing comma)
    const sanitized = [...paste.replaceAll(' ', '').trim().split(',')].filter(email => email);

    // store invalid / duplicate emails from user input for error generation
    const invalid = sanitized.filter(email => !validEmails.includes(email));
    // checks for duplicates in clipboardData or state (emails)
    const duplicate = sanitized.filter((email, index) => sanitized.indexOf(email) !== index || isInvited(email));
    const enrolled = sanitized.filter(email => isEnrolled(email));
    const pending = sanitized.filter(email => isPending(email));

    // remove duplicates from paste input not yet stored in state
    if (validEmails) {
      const toBeAdded = new Set(validEmails.filter(email => !isInvited(email) && !isEnrolled(email) && !isPending(email)));
      setEmails([...emails, ...toBeAdded]);
    }

    if (invalid || duplicate || enrolled || pending) {
      isValid({ invalid, duplicate, enrolled, pending });
    }
  };

  const isInvited = (email: string) => {
    return emails.includes(email);
  };

  const isEnrolled = (email: string) => {
    return !!existingDeviceOwners.filter(o => o.email === email && o.status === DeviceOwnerStatus.ENROLLED).length;
  };

  const isPending = (email: string) => {
    return !!existingDeviceOwners.filter(o => o.email === email && o.status === DeviceOwnerStatus.PENDING).length;
  };

  const isValid = (email: string | { invalid: string[]; duplicate: string[]; enrolled: string[]; pending: string[] }) => {
    const errors = [];

    const generateErrorMessage = (flaggedEmails: string[], errorDescription: string) => {
      const emailClause = flaggedEmails.length > 1 ? `${flaggedEmails.join(', ')} have` : `${flaggedEmails} has`;
      return `${emailClause} ${errorDescription}`;
    };

    const errorDescriptions: { [key: string]: string } = {
      duplicate: 'already been added to the invitation.',
      enrolled: 'already been enrolled.',
      pending: 'already been invited.',
    };

    // generate errors for email input pasted from clipboard
    if (typeof email === 'object') {
      for (const flagged in email) {
        if (flagged === 'invalid' && flagged.length) {
          const { invalid } = email;
          const invalidated =
            invalid.length > 1 ? `${invalid.join(', ')} are not valid email addresses.` : `${invalid} is not a valid email address.`;
          errors.push(`The input text ${invalidated}`);
        } else if (flagged.length) {
          errors.push(generateErrorMessage(email[flagged], errorDescriptions[flagged]));
        }
      }
    }
    // generate errors for manual user input
    if (typeof email === 'string') {
      const manualInputChecks: { [key: string]: boolean } = {
        invalid: !validateEmail(email),
        duplicate: isInvited(email),
        enrolled: isEnrolled(email),
        pending: isPending(email),
      };

      for (const flagged in manualInputChecks) {
        if (flagged === 'invalid' && manualInputChecks[flagged]) {
          errors.push(`The input text ${email} is not a valid email address.`);
        } else if (manualInputChecks[flagged]) {
          errors.push(`${email} has ${errorDescriptions[flagged]}`);
        }
      }
    }

    if (errors.length) {
      setEmailInputErrors(errors);
      return false;
    }

    setEmailInputErrors([]);
    return true;
  };

  return (
    <StyledDialog
      open={isOpen}
      aria-labelledby="add-device-owner-dialog-title"
      aria-describedby="add-device-owner-dialog-description"
      onClose={onClose}
      onKeyDown={handleSubmit}
      scroll="body"
    >
      <StyledDialogContent>
        <Typography variant="h3Medium" id="add-device-owner-modal-title">
          Invite device owners
          <StyledCloseIcon onClick={onClose} />
        </Typography>
        <StyledDivider />

        <div id="add-device-owner-modal-description">
          <Typography variant="h5Medium">Email Address</Typography>
          <br />
          <Typography variant="bodyRegular">Add devices to your organization via email (up to 50 comma-separated emails)</Typography>
        </div>
        <StyledLabel htmlFor="email-input">
          {emails.length > 0 && (
            <ChipContainer className="chip-wrapper">
              {emails.map(email => (
                <StyledChip
                  label={email}
                  key={email}
                  onDelete={() => {
                    handleDelete(email);
                    setEmailInputErrors([]);
                  }}
                />
              ))}
            </ChipContainer>
          )}
          <StyledInputBase
            id="email-input"
            placeholder="Email(s)"
            type="email"
            inputProps={{ multiple: true }}
            value={emailInputValue}
            onChange={handleAddEmails}
            onKeyDown={handleKeyDown}
            onPaste={handlePaste}
            autoFocus
            className="email-input"
          />
        </StyledLabel>
        {emailInputErrors && (
          <StyledErrorMessageWrapper>
            {emailInputErrors.map((error: string) => (
              <KwFormErrorMessage key={error} error={Boolean(emailInputErrors)} reason={error} />
            ))}
          </StyledErrorMessageWrapper>
        )}
        <div>
          <Typography variant="h5Medium">First Name (optional)</Typography>
          <StyledInputBase value={firstName} onChange={e => setFirstName(e.target.value)} placeholder="First Name" disabled={isDisabled} />
        </div>
        <div>
          <Typography variant="h5Medium">Last Name (optional)</Typography>
          <StyledInputBase value={lastName} onChange={e => setLastName(e.target.value)} placeholder="Last Name" disabled={isDisabled} />
        </div>
        <StyledButtonsContainer>
          <KwButton
            onClick={() => {
              onClose();
              setEmailInputValue('');
              setEmails([]);
              setEmailInputErrors([]);
              setIsDisabled(false);
            }}
            disabled={isLoading}
          >
            Cancel
          </KwButton>
          <KwButton variant="filled" onClick={inviteDeviceOwnersToOrg} disabled={isLoading} type="submit">
            Add
          </KwButton>
        </StyledButtonsContainer>
      </StyledDialogContent>
    </StyledDialog>
  );
};

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paperScrollBody {
    width: 540px;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
  background-color: #fafafa;
  & label {
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 4px;
  }
  & label:focus-within {
    border: 1px solid ${props => props.theme.palette.accent.primary};
  }
`;

const StyledButtonsContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
`;

const StyledDivider = styled(Divider)`
  margin: 8px 0;
`;

const StyledInputBase = styled(InputBase)`
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
  display: flex;
  height: 38px;
  margin: 14px 0;
  padding: 12px;
  input {
    font-size: 13px;
    opacity: 0.5;
  }
  &.Mui-disabled {
    background-color: ${props => props.theme.palette.greyOverride[200]};
  }
  &.Mui-focused {
    border: 1px solid ${props => props.theme.palette.accent.primary};
  }
  &.Mui-focused.email-input {
    border: none;
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;

const ChipContainer = styled('div')`
  padding: 6px;
`;

const StyledChip = styled(Chip)`
  margin: 2px;
`;

const StyledLabel = styled('label')`
  background-color: rgb(255, 255, 255);
  display: block;
  min-height: 38px;
  height: 100%;
  & > .MuiInputBase-root {
    border: none;
    appearance: none;
    font-size: 13px;
    margin: 0;
  }
`;

const StyledErrorMessageWrapper = styled('div')`
  margin: 14px 0;
`;
