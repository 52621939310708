import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Divider, List, ListItem, ListItemButton, ListItemText, Popover, Tooltip, Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { fetchService, useFetchService } from '../../utils/fetchService';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { useStickyResult } from '../../utils/useStickyResult';
import { verifyAuthPermission } from '../../auth/VerifyAuthPermission';
import { AuthPermissions } from '../../auth/AuthPermissions';

interface IProps {
  anchorEl: HTMLDivElement;
  handleClose: () => void;
}

export const useOrganization = (accessToken: string) => {
  const { data: organizationData, error: organizationError, mutate } = useFetchService(`/organizations/`, accessToken);
  const orgData = useStickyResult(organizationData);

  return { orgData, organizationError, mutate };
};

export const useCopyInviteLink = (accessToken: string) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyLink = async () => {
    try {
      const path = '/organizations/portal-user/invite';
      const response = await fetchService(path, accessToken);
      const inviteLink = response?.inviteLink;

      if (inviteLink) {
        await navigator.clipboard.writeText(inviteLink);
        setIsCopied(true);
      }
    } catch (error) {
      console.error('Error copying invite link:', error);
    }
  };

  useEffect(() => {
    // Reset the 'isCopied' state after 3 seconds to remove the indication that the invite link has been copied
    if (isCopied) {
      const timeout = setTimeout(() => {
        setIsCopied(false);
      }, 3000);
      return () => clearTimeout(timeout);
    }
    return undefined;
  }, [isCopied]);

  return { isCopied, handleCopyLink };
};

export default function SettingsDropDownMenu({ anchorEl, handleClose }: IProps) {
  const settingsOpen = Boolean(anchorEl);
  const settingsId = settingsOpen ? 'simple-popover' : undefined;
  const { isAuthenticated, isLoading, user, logout } = useAuth0();
  const [accessToken = ''] = useLocalStorage('accessToken', '');
  const { orgData, organizationError } = useOrganization(accessToken);
  const canCreateUser: boolean = verifyAuthPermission(accessToken, AuthPermissions.CREATE_USER);

  const { isCopied, handleCopyLink } = useCopyInviteLink(accessToken);

  return (
    <Popover
      id={settingsId}
      anchorEl={anchorEl}
      open={settingsOpen}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      aria-label="settings drop down menu"
    >
      <nav>
        <StyledList>
          {isAuthenticated && !isLoading && (
            <>
              <StyledListItem>
                <Typography variant="h3Bold">{user.name}</Typography>
              </StyledListItem>
              <StyledListItem>
                <Typography variant="label12Medium">{user.email}</Typography>
              </StyledListItem>
              {orgData && !organizationError && (
                <StyledListItem>
                  <Typography variant="label12Medium">{orgData?.name ?? ''}</Typography>
                </StyledListItem>
              )}
            </>
          )}
          <StyledDivider role="presentation" />
          <StyledListItem disablePadding>
            <ListItemButton component="a" href="/#/settings" onClick={handleClose}>
              <ListItemText primary="Settings" />
            </ListItemButton>
          </StyledListItem>
          {canCreateUser && (
            <StyledListItem disablePadding>
              <Tooltip title={isCopied ? 'Copied!' : 'Copy Invite Link'} placement="right">
                <ListItemButton component="a" onClick={handleCopyLink}>
                  <ListItemText primary="Copy Invite Link" />
                </ListItemButton>
              </Tooltip>
            </StyledListItem>
          )}
          <StyledListItem disablePadding>
            <ListItemButton component="a">
              <ListItemText
                primary="Logout"
                onClick={() => {
                  window.localStorage.setItem('accessToken', '');
                  logout({ returnTo: window.location.origin });
                }}
              />
            </ListItemButton>
          </StyledListItem>
        </StyledList>
      </nav>
    </Popover>
  );
}

const StyledList = styled(List)`
  padding: 8px 0 0 0;
`;

const StyledListItem = styled(ListItem)`
  &:nth-of-type(2),
  &:nth-of-type(3) {
    padding-top: 0;
  }
`;

const StyledDivider = styled(Divider)`
  margin: 10px 16px;
`;
