import { Routes, Route } from 'react-router-dom';
import Layout from '../../components/Layout';
// eslint-disable-next-line import/extensions
import { PolicyProfileMode } from '../../components/Policy/enum/PolicyProfileMode.enum';
import { PolicyInfoContext, PolicyInfoHook } from '../../components/Policy/PolicyInfoContext';
import { PolicyProfilePage } from '../../components/Policy/PolicyProfilePage';
import PoliciesTable from '../../components/Policy/PoliciesTable';

export default function Policies() {
  const {
    policyName,
    policyEnabled,
    policyDescription,
    policyCreatedOn,
    policyFormErrors,
    setPolicyName,
    setPolicyEnabled,
    setPolicyDescription,
    setPolicyCreatedOn,
    setPolicyFormErrors,
  } = PolicyInfoHook();

  return (
    <PolicyInfoContext.Provider
      value={{
        policyName,
        policyEnabled,
        policyDescription,
        policyCreatedOn,
        policyFormErrors,
        setPolicyName,
        setPolicyEnabled,
        setPolicyDescription,
        setPolicyCreatedOn,
        setPolicyFormErrors,
      }}
    >
      <Routes>
        <Route element={<Layout />}>
          <Route path="create" element={<PolicyProfilePage mode={PolicyProfileMode.CREATE} />} />
          <Route path="edit/:policyId" element={<PolicyProfilePage mode={PolicyProfileMode.EDIT} />} />
          <Route path="view/:policyId" element={<PolicyProfilePage mode={PolicyProfileMode.VIEW} />} />
          <Route path="/" element={<PoliciesTable />} />
        </Route>
      </Routes>
    </PolicyInfoContext.Provider>
  );
}
