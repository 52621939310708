import { Card, CardContent, InputBase, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { SettingsMode } from './enum/SettingsMode.enum';
import { verifyAuthPermission } from '../../auth/VerifyAuthPermission';
import { AuthPermissions } from '../../auth/AuthPermissions';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { useOrganization } from './SettingsDropDownMenu';
import { KwButton } from '../../kw-ui-components/KwButton/KwButton';
import { sendHttpRequest } from '../../utils/network.service';
import { validateEmail } from '../../utils/emailValidation';
import { KwFormErrorMessage } from '../../kw-ui-components/KwFormErrorMessage';

interface OrganizationSettingsCardProps {
  isAdmin: boolean;
  mode: SettingsMode;
  orgData: { [key: string]: string };
  setMode: React.Dispatch<React.SetStateAction<SettingsMode>>;
  supportEmail: string;
  setSupportEmail: React.Dispatch<React.SetStateAction<string>>;
  resetInputValues: () => void;
  emailInputError: string;
  setEmailInputError: React.Dispatch<React.SetStateAction<string>>;
}

const OrganizationSettingsCard = ({
  isAdmin,
  mode,
  orgData,
  setMode,
  supportEmail,
  setSupportEmail,
  resetInputValues,
  emailInputError,
  setEmailInputError,
}: OrganizationSettingsCardProps) => {
  const isEditMode = mode === SettingsMode.EDIT;
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (supportEmail.length) {
      setIsSaveButtonDisabled(false);
    }
    if (supportEmail.length === 0) {
      setIsSaveButtonDisabled(true);
    }
  }, [supportEmail]);

  const updateSupportEmail = async () => {
    if (validateEmail(supportEmail)) {
      const body = { supportEmail };
      await sendHttpRequest({
        path: `${process.env.ORGANIZATION_SERVICE_URL}/organizations/`,
        method: 'PUT',
        body,
        successMessage: 'Successfully updated Q-Scout support email',
        errorMessage: 'Error updating Q-Scout support email',
        enqueueSnackbar,
      });
      resetInputValues();
    } else {
      setEmailInputError('Please enter a valid email address');
    }
  };

  return (
    <StyledCard>
      <StyledCardContent>
        <StyledTypography variant="h4Bold">Support Email</StyledTypography>
        <StyledTypography variant="bodyRegular">
          Enter the email address where Q-Scout in-app support requests should be sent.
        </StyledTypography>
        <StyledTypography variant="h5SemiBold">Email</StyledTypography>
        {isAdmin && isEditMode ? (
          <>
            <StyledInput
              placeholder={supportEmail.length ? supportEmail : orgData.supportEmail}
              onChange={e => {
                setSupportEmail(e.target.value);
              }}
              inputProps={{ 'aria-label': 'supportEmail' }}
            />
            {emailInputError && (
              <StyledErrorMessageWrapper>
                <KwFormErrorMessage key={emailInputError} error={Boolean(emailInputError)} reason={emailInputError} />
              </StyledErrorMessageWrapper>
            )}
            <StyledButtonWrapper>
              <StyledLink onClick={resetInputValues}>Cancel</StyledLink>
              <StyledKwButton variant="filled" disabled={isSaveButtonDisabled} onClick={updateSupportEmail}>
                Save
              </StyledKwButton>
            </StyledButtonWrapper>
          </>
        ) : (
          <>
            <StyledTypography>{orgData.supportEmail}</StyledTypography>
            <StyledButtonWrapper>
              <StyledKwButton variant="filled" onClick={() => setMode(SettingsMode.EDIT)}>
                Edit
              </StyledKwButton>
            </StyledButtonWrapper>
          </>
        )}
      </StyledCardContent>
    </StyledCard>
  );
};

export function OrganizationSettings() {
  const [accessToken = ''] = useLocalStorage('accessToken', '');
  const [mode, setMode] = useState(SettingsMode.READ);
  const [supportEmail, setSupportEmail] = useState('');
  const [emailInputError, setEmailInputError] = useState('');

  const canUpdateOrg = verifyAuthPermission(accessToken, AuthPermissions.UPDATE_ORG);
  const { orgData, organizationError, mutate } = useOrganization(accessToken);

  const resetInputValues = () => {
    setSupportEmail('');
    setMode(SettingsMode.READ);
    setEmailInputError('');
    mutate();
  };

  if (organizationError) return <div>failed to load</div>;

  return (
    <OrganizationSettingsCard
      isAdmin={canUpdateOrg}
      mode={mode}
      orgData={orgData}
      setMode={setMode}
      supportEmail={supportEmail}
      setSupportEmail={setSupportEmail}
      resetInputValues={resetInputValues}
      emailInputError={emailInputError}
      setEmailInputError={setEmailInputError}
    />
  );
}

const StyledCard = styled(Card)`
  width: 100%;
  min-width: 300px;
  max-width: 400px;
`;

const StyledCardContent = styled(CardContent)`
  background-color: #fafafa;
`;

const StyledTypography = styled(Typography)`
  margin: 20px 0;
  display: block;
`;

const StyledInput = styled(InputBase)`
  width: 100%;
  background-color: #ffffff;
  border: 1px solid rgb(198, 198, 198);
  border-radius: 5px;
  padding: 4px;
  &::before {
    border-bottom: none;
  }
`;

const StyledButtonWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  column-gap: 14px;
  margin-top: 24px;
`;

const StyledKwButton = styled(KwButton)`
  width: 90px;
  height: 48px;
  padding: 8px 30px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  margin: 14px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &:nth-of-type(1) {
    color: #487f87;
  }
`;

const StyledErrorMessageWrapper = styled('div')`
  margin: 14px 0;
`;
