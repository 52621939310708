import { FormControl, InputLabel, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import { useSelect } from 'use-mui';
import { KwSelect, SelectArrowDown, SelectArrowUp, StyledOutlineInput } from '../../kw-ui-components/KwSelect';

interface IProps {
  title: string;
  label: string;
  stateValue: string;
  handleDateRangeChange: (event, type: string) => void; // fixme
}

export default function DeviceOwnersDateRangeSelect({ title, label, stateValue, handleDateRangeChange }: IProps) {
  // using 1 day in future to account for timestamps < 24 hours
  const tomorrow = dayjs().add(1, 'day').toISOString().split('T')[0];
  const lastWeek = dayjs(tomorrow).subtract(7, 'day').toISOString().split('T')[0];
  const lastMonth = dayjs(tomorrow).subtract(30, 'day').toISOString().split('T')[0];
  // hardcoding safe start date for greater than 30 days
  const startDate = dayjs('2022-01-01').toISOString().split('T')[0];

  const { open, handleOpen, handleClose } = useSelect({
    defaultValue: [],
  });

  const dateRange = [
    {
      label: 'All',
      value: '',
    },
    {
      label: 'Last 7 days',
      value: `${lastWeek}:${tomorrow}`,
    },
    {
      label: 'Last 30 days',
      value: `${lastMonth}:${tomorrow}`,
    },
    {
      label: 'Greater than 30 days',
      value: `${startDate}:${tomorrow}`,
    },
  ];

  return (
    <FormControl variant="filled">
      <StyledInputLabel id={`${label}-select-label`} disableAnimation={true} shrink={false}>
        {title}:
      </StyledInputLabel>
      <StyledSelect
        labelId={`${label}-select-label`}
        id={`${label}-select`}
        variant="filled"
        value={stateValue}
        label={`${title}:`}
        input={<StyledOutlineInput />}
        onChange={e => handleDateRangeChange(e, label)}
        onOpen={handleOpen}
        onClose={handleClose}
        autoWidth
        displayEmpty
        IconComponent={_props => <>{open ? <SelectArrowUp /> : <SelectArrowDown />}</>}
      >
        {dateRange.map(range => (
          <StyledMenuItem key={range.value} value={range.value}>
            {range.label}
          </StyledMenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
}

const StyledInputLabel = styled(InputLabel)`
  font-size: 14px;
  padding-top: 1px;
`;

const StyledSelect = styled(KwSelect)`
  min-width: 330px;
  width: auto;
  height: 55px;
  div.MuiSelect-select.MuiOutlinedInput-input {
    display: flex;
    justify-content: flex-end;
    align-self: center;
    font-size: 14px;
    line-height: 1.4375em;
    padding-right: 4px;
  }
  .MuiInputBase-input:focus {
    background-color: transparent;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  min-width: 330px;
`;
