import { createContext, useState } from 'react';

export const ManagedAppsInfoHook = () => {
  const [platform, setPlatform] = useState(null);
  const [mode, setMode] = useState(null);
  const [app, setApp] = useState({});
  const [searchHistory, setSearchHistory] = useState('');

  return {
    platform,
    setPlatform,
    mode,
    setMode,
    app,
    setApp,
    searchHistory,
    setSearchHistory,
  };
};

export const ManagedAppsInfoContext = createContext(undefined);
// issue reloading /add page...